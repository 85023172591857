import React, { useEffect, useRef, useState } from "react";
import { useGameBoardStore } from "src/global-stores/useGameBoardStore";
import { useGameInfoStore } from "src/global-stores/useGameInfoStore";
import { useDimensions } from "src/hooks/useDimentions";
import { IGameState } from "src/interfaces/IGame";
import BattleField from "./BattleField";
import EndGame from "./EndGame";
import GameInfo from "./GameInfo";

interface DisplayedGameStateProps {
  timeLeft: number;
  stopTimer: () => void;
}

const DisplayedGameState: React.FC<DisplayedGameStateProps> = ({
  timeLeft,
  stopTimer,
}) => {
  const [gameInfo, players, gameState] = useGameInfoStore((s) => [
    s.gameInfo,
    s.players,
    s.gameState,
  ]);

  const {
    start: startGameBoard,
    current: currentGameBoard,
    end: endGameBoard,
    effects: gameBoardEffects,
  } = useGameBoardStore();

  const gameBoardContainerRef = useRef<HTMLDivElement>(null);
  const dims = useDimensions(gameBoardContainerRef);

  const [scale, setScale] = useState(0.75);

  useEffect(() => {
    const { width, height } = dims;

    let divheight;
    let divwidth;
    if(width === 0 || height === 0) {
      divheight = gameBoardContainerRef.current ? gameBoardContainerRef.current.offsetHeight : 0;
      divwidth = gameBoardContainerRef.current ? gameBoardContainerRef.current.offsetWidth : 0;
    } else {
      divheight = height;
      divwidth = width;
    }

    const mapheight = startGameBoard?.map.height;
    const mapwidth = startGameBoard?.map.width;

    if (mapheight && mapwidth) {
      const newScale = Math.min((divheight - 260) / mapheight, divwidth / mapwidth) * 0.95;
      setScale(newScale);
    }
  }, [dims, gameBoardContainerRef, startGameBoard, currentGameBoard]);

  let toDisplay;

  if (gameState === IGameState.LOBBY) {
    toDisplay = (
      <div className="h-full flex items-end">
        <GameInfo
          gameState={gameState}
          gameInfo={gameInfo}
          players={players}
          prepareTime={timeLeft}
          stopTimer={stopTimer}
        />
      </div>
    );
  } else if (gameState === IGameState.START || gameState === IGameState.PLAY) {
    toDisplay = (
      <div
        ref={gameBoardContainerRef}
        className="relative flex items-end h-full"
      >
        <div className="absolute bottom-60 top-0 flex items-center justify-center w-full">
          <BattleField
            startState={startGameBoard}
            currentState={currentGameBoard}
            effects={gameBoardEffects}
            scale={scale}
          />
        </div>
        <GameInfo
          gameState={gameState}
          gameInfo={gameInfo}
          players={players}
          prepareTime={timeLeft}
          stopTimer={stopTimer}
        />
      </div>
    );
  } else if (gameState === IGameState.END) {
    toDisplay = <EndGame players={players} endGameBoard={endGameBoard} />;
  } else {
    toDisplay = <div>Loading...</div>;
  }

  return toDisplay;
};

export default DisplayedGameState;
