/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-empty-pattern */
import React from "react";
import MyRouter from "./components/MyRouter";
import ToastController from "./modules/errors/ToastController";

interface AppProps {}

const App: React.FC<AppProps> = ({}) => {
  return (
    <React.Fragment>
      <ToastController />
      <MyRouter />
    </React.Fragment>
  );
};

export default App;
