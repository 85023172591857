import { CheckIcon, ClipboardCopyIcon, XIcon } from "@heroicons/react/outline";
import React from "react";
import { useHistory } from "react-router-dom";
import Button from "src/components/Button";
import { __leave_command__, __start_command__ } from "src/constants/constants";
import { Language, useEditorStore } from "src/global-stores/useEditorStore";
import { useIsHostStore } from "src/global-stores/useIsHostStore";
import { useTankSpecsStore } from "src/global-stores/useTankSpecsStore";
import { IGameState } from "src/interfaces/IGame";
import { IJoin } from "src/interfaces/IJoin";
import { ILobby } from "src/interfaces/ILobby";
import { client } from "../../ws/WSWrapper";

interface GameInfoProps {
  gameInfo?: ILobby;
  players: IJoin[];
  prepareTime?: number;
  gameState: IGameState;
  stopTimer: () => void;
}

const gameMode = "FFA deathmatch";

const GameInfo: React.FC<GameInfoProps> = ({
  gameInfo,
  players,
  prepareTime,
  gameState,
  stopTimer,
}) => {
  const { isHost } = useIsHostStore();
  // const [readyClicked, setReadyClicked] = useState(false);
  const { push } = useHistory();
  const gameStateLabel = () => {
    if (gameState === IGameState.LOBBY) return "Host did not start";

    const notReadyPlayers = players.filter((p) => !p.ready);
    if (notReadyPlayers.length > 0) {
      return `${prepareTime}`;
    } else {
      return `Game started`;
    }
  };

  const isCurrentPlayerReady = () => {
    const readyPlayers = players.filter(p => p.ready);
    const yourPlayer = readyPlayers.filter(p => p.slot === gameInfo?.yourSlot);
    if (yourPlayer.length > 0) {
      return yourPlayer[0];
    }
    return false;
  };

  const [language, content] = useEditorStore((s) => [
    s.language,
    s.content,
    s.setContent,
  ]);
  const [speed, turnSpeed, fireRate, explosionRadius] = useTankSpecsStore(
    (s) => [s.speed, s.turn_speed, s.fire_rate, s.explosion_radius]
  );

  return (
    <div
      className={`w-full p-3 border-t-8 border-accent-script h-60 bg-primary dark:bg-gray-500`}
    >
      <div className="flex flex-col justify-between h-full">
        <div
          className="flex items-top space-x-10"
          style={{ maxHeight: "calc(100% - 40px)" }}
        >
          <div className="flex flex-col space-y-4 space-between items-start h-full">
            <div className="flex flex-col space-y-3 h-full">
              <div>
                <p className="text-xs text-gray-200">Invite code</p>
                <div className="flex items-center space-x-2">
                  <p>{gameInfo?.invite}</p>
                  <Button
                    onClick={() =>
                      navigator.clipboard.writeText(gameInfo?.invite || "")
                    }
                    transparent
                    padding={false}
                    icon={
                      <ClipboardCopyIcon className="w-4 h-4 text-gray-400" />
                    }
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <p className="text-xs text-gray-200">Gamemode</p>
                <p>{gameMode}</p>
              </div>
            </div>
          </div>
          <div className="space-y-1">
            <p className="text-xs text-gray-200">
              Players [{players.length}/{gameInfo?.maxPlayers}]
            </p>
            <div className="space-y-1 overflow-y-auto h-full">
              {players
                .sort((a, b) => a.slot - b.slot)
                .map((p, i) => (
                  <div key={i} className="flex items-center space-x-2">
                    <div
                      className="w-5 h-5 rounded"
                      style={{
                        backgroundColor: gameInfo?.colours[p.colour].code,
                      }}
                    ></div>
                    {
                      p.slot === gameInfo?.yourSlot ? (
                        <div>{p.username + " (you)"}</div>
                      ) : (
                        <div> { p.username }</div>
                      )
                    }
                    {p.ready ? (
                      <CheckIcon className="w-5 h-5 text-secondary-green-light" />
                    ) : (
                      <XIcon className="w-5 h-5 text-secondary-red-light" />
                    )}
                  </div>
                ))}
            </div>
          </div>
          <div>
            <p className="text-xs text-gray-200">Prepare time</p>
            <p>{gameStateLabel()}</p>
          </div>
        </div>
        <div className=" flex items-center justify-between">
          <Button
            label="Leave"
            bgColorDark="red"
            bgColorLight="red"
            textColorLight="primary"
            onClick={() => {
              client.send(__leave_command__);
              client.close();
              // window.location.reload();
              push('/');
            }}
            rounded
          />
          {isHost && gameState === IGameState.LOBBY ? (
            <Button
              label="Start battle preparation"
              onClick={() => {
                client.send(__start_command__);
              }}
              disabled={!isHost}
              bgColorDark="green"
              bgColorLight="green"
              textColorLight="primary"
              rounded
            />
          ) : (
            <Button
              label={isCurrentPlayerReady() ? "Resubmit Changes" : "Ready for Battle"}
              bgColorLight={isCurrentPlayerReady() ? "green" : "accentTanks"}
              bgColorDark={isCurrentPlayerReady() ? "green" : "accentTanks"}
              textColorLight="primary"
              textColorDark="primary"
              disabled={gameState !== IGameState.START}
              rounded
              onClick={() => {
                stopTimer();
                // setReadyClicked(true);

                client.send(
                  JSON.stringify({
                    type: "ready",
                    language: Object.values(Language).indexOf(language),
                    skills: [speed, turnSpeed, fireRate, explosionRadius],
                    script: content,
                  })
                );
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default GameInfo;
