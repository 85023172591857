import combine from "zustand";
import { persist } from "zustand/middleware";

interface TankSpecsState {
  speed: number;
  turn_speed: number;
  fire_rate: number;
  explosion_radius: number;
  setSpeed: (speed: number) => void;
  setTurnSpeed: (turnSpeed: number) => void;
  setFireRate: (fireRate: number) => void;
  setExplosionRadius: (explosionRadius: number) => void;
}

export const useTankSpecsStore = combine<TankSpecsState>(
  persist(
    (set, get) => ({
      speed: 0,
      turn_speed: 0,
      fire_rate: 0,
      explosion_radius: 0,
      setSpeed: (speed) => set({ speed }),
      setTurnSpeed: (turn_speed) => set({ turn_speed }),
      setFireRate: (fire_rate) => set({ fire_rate }),
      setExplosionRadius: (explosion_radius) => set({ explosion_radius }),
    }),
    {
      name: "tank-specs-storage",
    }
  )
);
