import React from "react";
import ProgressBar from "./ProgressBar";

interface TankTestProps {
  radius: number;
  x_pos: number;
  y_pos: number;
  bearing: number;
  healthPercent: number;
  heatPercent: number;
  color: string;
}

const absCordFromParentChildDims = (
  parentWidthHeight: number,
  childWidthHeight: number
) => (parentWidthHeight - childWidthHeight) / 2;
const shadeColor = (color: string, percent: number) =>
  "#" +
  color
    .replace(/^#/, "")
    .replace(/../g, (c) =>
      (
        "0" + Math.min(255, Math.max(0, parseInt(c, 16) + percent)).toString(16)
      ).substr(-2)
    );

const TankTest: React.FC<TankTestProps> = ({
  radius,
  x_pos,
  y_pos,
  bearing,
  healthPercent,
  heatPercent,
  color,
}) => {
  const viewBox = {
    widthHeight: radius * 4 + radius,
  };
  const strokeColor = shadeColor(color, -30);

  return (
    <svg
      width={viewBox.widthHeight}
      height={viewBox.widthHeight}
      viewBox={`0 0 ${viewBox.widthHeight} ${viewBox.widthHeight}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        position: "absolute",
        left: x_pos - viewBox.widthHeight / 2,
        bottom: y_pos - viewBox.widthHeight / 2,
        transformOrigin: "center",
        transform: `rotate(${bearing}deg)`,
      }}
    >
      <g id="full_tank">
        <g id="tank">
          <rect
            id="body"
            x={absCordFromParentChildDims(viewBox.widthHeight, radius * 2)}
            y={absCordFromParentChildDims(viewBox.widthHeight, radius * 2)}
            width={radius * 2}
            height={radius * 2}
            rx={radius / 5}
            fill={color}
            stroke={strokeColor}
            strokeWidth="2"
          />
          <rect
            id="cannon"
            x={absCordFromParentChildDims(viewBox.widthHeight, radius / 3)}
            y={
              absCordFromParentChildDims(viewBox.widthHeight, radius * 2) -
              radius
            }
            width={radius / 3}
            height={radius * 1.8}
            fill={color}
            stroke={strokeColor}
            strokeWidth="2"
          />
          <circle
            id="turret"
            cx={viewBox.widthHeight / 2}
            cy={viewBox.widthHeight / 2}
            r={radius - radius / 3}
            fill={color}
            stroke={strokeColor}
            strokeWidth="2"
          />
        </g>
        <g
          id="stats"
          style={{
            transformOrigin: "center",
            transform: `rotate(${-bearing}deg)`,
          }}
        >
          <ProgressBar
            x={absCordFromParentChildDims(viewBox.widthHeight, radius * 2)}
            y={
              (absCordFromParentChildDims(viewBox.widthHeight, radius * 2) +
                radius) *
              1.45
            }
            type={1}
            width={radius * 2}
            height={radius / 2}
            stroke="black"
            percent={healthPercent}
          />
          <ProgressBar
            x={absCordFromParentChildDims(viewBox.widthHeight, radius * 2)}
            y={
              (absCordFromParentChildDims(viewBox.widthHeight, radius * 2) +
                radius) *
              1.72
            }
            type={2}
            width={radius * 2}
            height={radius / 2}
            stroke="black"
            percent={heatPercent}
          />
        </g>
      </g>
    </svg>
  );
};

export default TankTest;
