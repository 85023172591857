import * as React from 'react';

interface FileInputProps extends React.ComponentPropsWithoutRef<'input'> {
    children: JSX.Element
}

const FileInput: React.FC<FileInputProps> = ({ children, onChange, ...props }) => {
    return (<div>
        <label htmlFor="file-input">
            {children && React.cloneElement(children, {
                onClick: () => {
                    const fileinput = document.getElementById('file-input');
                    fileinput?.click();
                }
            })}
        </label>
        <input id="file-input" type="file" className="hidden" onChange={onChange} {...props} />
    </div>);
};

export default FileInput;
