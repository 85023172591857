import combine from "zustand";

interface IsHostState {
  isHost: boolean;
  setIsHost: (isHost: boolean) => void;
}

export const useIsHostStore = combine<IsHostState>((set) => ({
  isHost: false,
  setIsHost: (isHost) => set({ isHost }),
}));
