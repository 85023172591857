import React, { useEffect } from "react";
import Layout from "src/components/Layout";
import { __start_command__ } from "src/constants/constants";
import { Language, useEditorStore } from "src/global-stores/useEditorStore";
import { useGameInfoStore } from "src/global-stores/useGameInfoStore";
import { useTankSpecsStore } from "src/global-stores/useTankSpecsStore";
import { useTimer } from "src/hooks/useTimer";
import { IGameState } from "src/interfaces/IGame";
import ActionsSide from "src/modules/gamepage/actionsside/ActionsSide";
import DisplayedGameState from "src/modules/gamepage/gameside/DisplayedGameState";
import { client } from "src/modules/ws/WSWrapper";

interface GamePageProps {}

const GamePage: React.FC<GamePageProps> = ({}) => {
  const [language, content] = useEditorStore((s) => [
    s.language,
    s.content,
    s.setContent,
  ]);
  const [speed, turnSpeed, fireRate, explosionRadius] = useTankSpecsStore(
    (s) => [s.speed, s.turn_speed, s.fire_rate, s.explosion_radius]
  );
  const { gameInfo, gameState } = useGameInfoStore();

  const {
    start: startTimer,
    timeLeft,
    stop: stopTimer,
  } = useTimer({
    onExpire: () =>
      client.send(
        JSON.stringify({
          type: "ready",
          language: Object.values(Language).indexOf(language),
          skills: [speed, turnSpeed, fireRate, explosionRadius],
          script: content,
        })
      ),
    autoStart: false,
  });

  useEffect(() => {
    if (gameState === IGameState.START && gameInfo) {
      startTimer(gameInfo.prepareSeconds);
    } else {
      stopTimer();
    }
  }, [gameState, gameInfo]);

  return (
    <Layout
      rightSide={
        <ActionsSide
          stopTimer={stopTimer}
          timeLeft={timeLeft}
        />
      }
      leftSide={
        <DisplayedGameState
          timeLeft={timeLeft}
          stopTimer={stopTimer}
        />
      }
    />
  );
};

export default GamePage;
