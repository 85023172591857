import * as React from "react";

const bgColors = {
  accentTanks: "bg-accent-tanks",
  accentScript: "bg-accent-script",
  green: "bg-secondary-green-light",
  red: "bg-secondary-red-light"
};

const textColors = {
  primary: "text-primary",
  secondary: "text-secondary",
  "gray-200": "text-gray-200",
};

interface ButtonProps extends React.ComponentPropsWithoutRef<"button"> {
  label?: string;
  icon?: React.ReactNode;
  rounded?: boolean;
  className?: string;
  transparent?: boolean;
  padding?: boolean;
  bgColorLight?: keyof typeof bgColors;
  bgColorDark?: keyof typeof bgColors;
  textColorLight?: keyof typeof textColors;
  textColorDark?: keyof typeof textColors;
}

const Button: React.FC<ButtonProps> = ({
  label = "",
  icon,
  rounded,
  transparent = false,
  padding = true,
  className = "",
  bgColorLight = "accentScript",
  bgColorDark = "accentScript",
  textColorLight = "secondary",
  textColorDark = "primary",
  disabled,
  onClick,
  ...props
}) => {
  const bg = !transparent
    ? `${bgColors[bgColorLight]} dark:${bgColors[bgColorDark]}`
    : "";
  const text = `${textColors[textColorLight]} dark:${textColors[textColorDark]}`;
  const p = padding ? "py-2 px-4" : "";
  const round = rounded ? "rounded-lg" : "";
  const opacity = disabled ? `opacity-70` : `opacity-100`;

  const cs = `${className} ${bg} ${text} ${p} ${round} ${opacity} flex items-center`;

  return (
    <button className={cs} onClick={!disabled ? onClick : undefined} {...props}>
      {icon && icon}
      {label && <div className={`${icon ? `ml-2` : ``}`}>{label}</div>}
    </button>
  );
};

export default Button;
