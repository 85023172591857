import create from "zustand";
import { persist } from "zustand/middleware";

interface TabState {
  tabIndex: number;
  setTabIndex: (tab: number) => void;
}

export const useTabStore = create<TabState>(
  persist(
    (set, get) => ({
      tabIndex: 0,
      setTabIndex: (tab) => set((s) => ({ tabIndex: tab })),
    }),
    {
      name: "tab-storage",
    }
  )
);
