export type IError = {
    reason: IErrorReason;
    traceback?: string;
};

export enum IErrorReason {
    TooManyPlayers = 0,
    LobbyFull = 1,
    ColourTaken = 2,
    TooManySkillPoints = 3,
    AlreadyStarted = 4,
    InvalidFormat = 5,
    NotFound = 6,
    InvalidInvite = 7,
    NotInSession = 8,
    NotHost = 9,
    NeedMorePlayers = 10,
    InvalidValue = 11,
    ScriptError = 12
}
