import React, { useEffect } from "react";
import Tab from "src/components/Tab";
import TabBar from "src/components/TabBar";
import { useTabStore } from "src/global-stores/useTabStore";
import { useToastStore } from "src/global-stores/useToastsStore";
import { useScreenType } from "src/hooks/useScreenType";
// import { IGameState } from "src/interfaces/IGame";
import DisplayedGameState from "../gameside/DisplayedGameState";
import Editor from "./tabs/Editor";
import HelpTab from "./tabs/HelpTab";
import InfoTab from "./tabs/InfoTab";
import TankTab from "./tabs/TankTab";

interface ActionSideProps {
  stopTimer: () => void;
  timeLeft: number;
}

const ActionsSide: React.FC<ActionSideProps> = ({ stopTimer, timeLeft }) => {
  const { tabIndex: tab, setTabIndex: setTab } = useTabStore();
  const screenType = useScreenType();
  const { showToast } = useToastStore();

  const tabs = {
    0: <InfoTab />,
    1: <Editor />,
    2: <TankTab />,
    3: <HelpTab />,
    4: screenType === "1-cols" && (
      <DisplayedGameState timeLeft={timeLeft} stopTimer={stopTimer} />
    ),
  };

  useEffect(() => {
    if (screenType !== "1-cols" && tab === 4) {
      setTab(1);
    }
  }, [tab, screenType]);

  return (
    <div className="h-full w-full flex flex-col">
      <TabBar selected={tab} setSelected={(i) => setTab(i)}>
        <Tab label="Info" />
        <Tab label="Editor" />
        <Tab
          label="Tank"
          // disabled={gameState < IGameState.LOBBY}
          handleClickWhenDisabled={() =>
            showToast({
              type: "warning",
              message: "'Tank tab' is not avaible in this game state",
              duration: 7000,
            })
          }
        />
        <Tab label="Help" />
        {screenType === "1-cols" && (
          <Tab
            label="Game"
            handleClickWhenDisabled={() =>
              showToast({
                type: "warning",
                message: "'Game tab' is not avaible in this game state",
                duration: 7000,
              })
            }
          />
        )}
      </TabBar>
      <div className={`h-full w-full shadow-lg overflow-y-auto`}>
        {tabs[tab]}
      </div>
    </div>
  );
};

export default ActionsSide;
