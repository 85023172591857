import React from "react";

interface TabWrapperProps {
  className?: string;
}

const TabWrapper: React.FC<TabWrapperProps> = ({
  children,
  className = "",
}) => {
  const cs = `${className} p-4 w-full overflow-auto`;
  return <div className={cs}>{children}</div>;
};

export default TabWrapper;
