/* eslint-disable react-hooks/exhaustive-deps */
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-dracula";
import "ace-builds/src-noconflict/theme-tomorrow";
import React, { useEffect, useRef } from "react";
import { useEditorStore } from "src/global-stores/useEditorStore";
import { Theme, useThemeStore } from "src/global-stores/useThemeStore";
import { pythonSnippet } from "src/snippets/python";
import EditorInfo from "../../../../components/EditorInfo";

interface EditorProps {}

interface Annotation {
  row: number;
  column: number;
  message: string;
  type: string;
  text: string;
}

const snippets = {
  python: pythonSnippet,
};

const Editor: React.FC<EditorProps> = ({}) => {
  const { language, content, setCursorPosition, setContent } = useEditorStore();
  const editorEl = useRef<any>(null);

  const theme = useThemeStore((s) => s.currentTheme);

  const editorTheme = theme === Theme.light ? "tomorrow" : "dracula";

  const annotations: Annotation[] = [];

  useEffect(() => {
    if (!content || content === "") setContent(snippets[language]);
  }, []);

  useEffect(() => {
    const { editor } = editorEl.current;
    if (annotations.length && editor) {
      editor.renderer.once("afterRender").then(() => {
        editor.getSession().setAnnotations(annotations);
      });
    }
  }, [JSON.stringify(annotations)]);

  return (
    <div className="w-full h-full flex flex-col">
      <AceEditor
        mode={language}
        value={content}
        theme={editorTheme}
        ref={editorEl}
        fontSize={14}
        style={{ height: "100%", width: "100%" }}
        width="100%"
        height="100%"
        onChange={(v) => setContent(v)}
        showGutter={true}
        setOptions={{
          enableLiveAutocompletion: true,
          enableBasicAutocompletion: true,
        }}
        annotations={annotations}
        onCursorChange={(s) => setCursorPosition(s.getCursor())}
      />
      <EditorInfo />
    </div>
  );
};

export default Editor;
