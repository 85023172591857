import { SelectorIcon } from "@heroicons/react/outline";
import { useRef, useState } from "react";
import { useDimensions } from "src/hooks/useDimentions";

interface SelectorProps<T> {
  list: T[];
  selected: T;
  setSelected: (e: T) => void;
}

const Selector = <T, /**/>({
  list,
  selected,
  setSelected,
}: SelectorProps<T>) => {
  const [open, setOpen] = useState(false);
  const containerRef = useRef<HTMLButtonElement>(null);
  const dims = useDimensions(containerRef);

  const handleBlur = () => setOpen(false);

  return (
    <div className="w-40 relative">
      {open && (
        <div
          className={`absolute z-10 bg-primary dark:bg-gray-500 flex flex-col items-start border border-gray-100 dark:border-gray-400  w-full rounded-lg shadow-lg`}
          style={{ bottom: Math.floor(dims.height + 5) }}
        >
          {list.map((e, i) => (
            <button
              key={i}
              className={`${selected === e ? `text-gray-300 dark:text-gray-100` : ``
                } w-full text-left hover:bg-gray-100 dark:hover:bg-gray-400 px-3 py-2 rounded-lg`}
              onMouseDown={() => {
                setSelected(e);
                setOpen(false);
              }}
            >
              {e}
            </button>
          ))}
        </div>
      )}
      <button
        ref={containerRef}
        onBlur={handleBlur}
        onClick={() => setOpen((s) => !s)}
        className="shadow-inner border border-gray-100 dark:border-gray-400 rounded-lg py-2 px-3 flex items-center justify-between w-full bg-primary dark:bg-gray-500"
      >
        <div>{selected}</div>
        <SelectorIcon className="w-6 h-6" />
      </button>
    </div>
  );
};

export default Selector;
