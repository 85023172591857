import { useState, useEffect } from "react";

interface TimerArgs { autoStart?: boolean, onExpire: () => void }

export const useTimer = ({ autoStart = true, onExpire }: TimerArgs) => {
    const [timeLeft, setTimeLeft] = useState(0);
    const [started, setStarted] = useState(autoStart);
    const start = (seconds :number) => {
        setTimeLeft(seconds);
        setStarted(true);
    };
    const stop = () => setStarted(false);

    useEffect(() => {
        let timerId: NodeJS.Timeout;

        if(started) {
            if(timeLeft <= 0) {
                onExpire();
                stop();
            }
            timerId = setTimeout(() => setTimeLeft(t => t - 1), 1000);
        }
        return () => clearTimeout(timerId);
    }, [timeLeft, started]);

    return { start, stop, timeLeft };
};
