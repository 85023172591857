import React from "react";

interface ToggleProps {
  className?: string;
  checked: boolean;
  name?: string;
  handleClick?: () => void;
  startIcon: React.ReactNode;
  endIcon: React.ReactNode;
}

const Toggle: React.FC<ToggleProps> = ({
  className = "",
  checked,
  name = "",
  handleClick = () => {},
  startIcon,
  endIcon,
  ...newProps
}) => {
  let toggleClass =
    "block h-6 w-6 rounded-full bg-primary absolute z-10 transform transition duration-500 ease-linear bg-primary dark:bg-gray-500";
  const bgClass =
    "h-10 w-20 rounded-full p-2 relative m-auto cursor-pointer bg-gray-500 dark:bg-primary";

  if (checked) toggleClass += " translate-x-10";

  return (
    <label className="w-20 block" htmlFor="toggle">
      <div className={bgClass}>
        <div className={toggleClass}></div>
        <div className="flex justify-between items-center h-full mx-1">
          {endIcon}
          {startIcon}
        </div>
        <input
          className="absolute top-0 hidden"
          id="toggle"
          name={name}
          type="checkbox"
          checked={checked}
          onClick={handleClick}
          onChange={() => {}}
          {...newProps}
        />
      </div>
    </label>
  );
};

export default Toggle;
