import * as React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import WSWrapper from "src/modules/ws/WSWrapper";
import CreateOrJoinGamePage from "src/pages/CreateOrJoinGamePage";
import GamePage from "src/pages/GamePage";
import NavBar from "./NavBar";

interface MyRouterProps {}

const MyRouter: React.FC<MyRouterProps> = ({}) => {
  return (
    <Router>
      <WSWrapper>
        <div className="w-full h-full flex flex-col bg-primary dark:bg-gray-600 text-gray-500 dark:text-primary">
          <NavBar />
          <Switch>
            <Route exact path="/" component={CreateOrJoinGamePage} />
            <Route exact path="/game/:invite" component={GamePage} />
          </Switch>
        </div>
      </WSWrapper>
    </Router>
  );
};

export default MyRouter;
