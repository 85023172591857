import create from "zustand";
import { persist } from "zustand/middleware";

export enum Language {
  python = "python",
  java = "java",
  javascript = "javascript",
  json = "json",
}

interface ICursorPosition {
  row: number;
  column: number;
}

interface EditorLanguageState {
  language: Language;
  cursorPosition: ICursorPosition;
  content: string;
  setLanguage: (language: Language) => void;
  setCursorPosition: (position: ICursorPosition) => void;
  setContent: (content: string) => void;
}

export const useEditorStore = create<EditorLanguageState>(
  persist(
    (set, get) => ({
      language: Language.python,
      cursorPosition: { row: 0, column: 0 },
      content: "",
      setLanguage: (language) => set((s) => ({ language })),
      setCursorPosition: (position) =>
        set((s) => ({ cursorPosition: position })),
      setContent: (content) => set((s) => ({ content })),
    }),
    {
      name: "editor-storage",
    }
  )
);
