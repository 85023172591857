import * as React from "react";
import { Link } from "react-router-dom";

const textColors = {
  primary: "text-primary",
  secondary: "text-secondary",
  "gray-500": "text-gray-500",
};

const textSizes = {
  medium: "",
  big: "text-lg",
  "very-big": "text-xl",
};

interface IconLinkProps {
  label: string;
  to: string;
  icon: React.ReactNode;
  textColorLight?: keyof typeof textColors;
  textColoreDark?: keyof typeof textColors;
  textSize?: keyof typeof textSizes | string;
}

const IconLink: React.FC<IconLinkProps> = ({
  label,
  to,
  icon,
  textColorLight = "secondary",
  textColoreDark = "primary",
  textSize = "medium",
}) => {
  const text = `${textColors[textColorLight]} dark:${
    textColors[textColoreDark]
  } ${typeof textSize === "string" ? textSize : textSizes[textSize]}`;

  return (
    <Link to={to} className="flex items-center space-x-3">
      <div>{icon}</div>
      <div className={text}>{label}</div>
    </Link>
  );
};

export default IconLink;
