import { CheckIcon, XIcon } from "@heroicons/react/outline";
import React from "react";
import { IColour } from "src/interfaces/IColour";

interface ColourSelectionProps
  extends Omit<React.ComponentPropsWithoutRef<"button">, "colour"> {
  colour: IColour;
  selected: boolean;
  alreadySelected: boolean | (() => boolean)
}

const ColourSelection: React.FC<ColourSelectionProps> = ({
  colour,
  selected,
  alreadySelected,
  ...props
}) => {
  return (
    <button
      className={`w-36 h-32 shadow-xl flex flex-col bg-primary dark:bg-gray-500`}
      {...props}
      style={{
        borderBottomLeftRadius: "1rem",
        borderTopRightRadius: "1rem",
      }}
    >
      <div
        className={`w-full h-full relative`}
        style={{
          backgroundColor: colour.code,
          borderBottomLeftRadius: "1rem",
          borderTopRightRadius: "1rem",
        }}
      >
        {alreadySelected && !selected && <XIcon className="w-8 h-8 text-primary absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />}
        {selected && !alreadySelected && (
          <CheckIcon className="w-8 h-8 text-primary absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
        )}
      </div>
      <p
        className="flex items-center p-2 pl-3 text-sm"
        style={{ color: colour.code }}
      >
        {colour.name.toUpperCase()}
      </p>
    </button>
  );
};

export default ColourSelection;
