import React from "react";

interface WallProps {
  width: number;
  height: number;
  x_pos: number;
  y_pos: number;
  className?: string;
  scale: number;
}

const Wall: React.FC<WallProps> = ({
  width,
  height,
  x_pos,
  y_pos,
  scale,
  className = "",
}) => {
  const scaledWidth = width * scale;
  const scaledHeight = height * scale;
  const scaledXPos = x_pos * scale;
  const scaledYPos = y_pos * scale;
  return (
    <svg
      width={scaledWidth}
      height={scaledHeight}
      viewBox={`0 0 ${scaledWidth} ${scaledHeight}`}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        position: "absolute",
        left: scaledXPos - scaledWidth / 2,
        bottom: scaledYPos - scaledHeight / 2,
      }}
      className={`${className}`}
    >
      <rect width={scaledWidth} height={scaledHeight} />
    </svg>
  );
};

export default Wall;
