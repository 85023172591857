import React from "react";
import { useScreenType } from "src/hooks/useScreenType";

interface LayoutProps {
  rightSide: React.ReactNode;
  rightSideClassName?: string;
  leftSide: React.ReactNode;
  leftSideClassName?: string;
}

const Layout: React.FC<LayoutProps> = ({
  rightSide,
  rightSideClassName,
  leftSide,
  leftSideClassName,
}) => {
  const screenType = useScreenType();

  let layoutClass = "";
  let leftCN = leftSideClassName;
  let rightCN = rightSideClassName;
  if (screenType === "3-cols") {
    layoutClass = "flex";
    leftCN += " w-1/2";
    rightCN += " w-1/2 h-full flex flex-col items-center pt-2";
  } else if (screenType === "1-cols") {
    layoutClass = "flex flex-col";
    rightCN += " h-full w-full pt-2";
  }

  return (
    <div className={layoutClass} style={{ height: "calc(100% - 3.5rem)" }}>
      {screenType === "3-cols" && <div className={leftCN}>{leftSide}</div>}
      <div className={rightCN}>{rightSide}</div>
    </div>
  );
};

export default Layout;
