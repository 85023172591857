import { EffectType, IParticile } from "./../interfaces/IEffect";
import { IHit } from "src/interfaces/IHit";
import { ITank } from "src/interfaces/ITank";

const createParticles = () => {
  let particicles: IParticile[] = [];
  for (let j = 0; j < 10; j++) {
    const dx = Math.random() - 0.5;
    const dy = Math.random() - 0.5;
    particicles.push({
      dx,
      dy,
    });
  }
  return particicles;
};

export const createShotEffect = ({
  x_pos,
  y_pos,
  radius,
  bearing,
  tank_id,
}: Pick<ITank, "x_pos" | "y_pos" | "bearing" | "radius"> & {
  tank_id: number;
}) => {
  const particles = createParticles();

  return {
    type: EffectType.SHOT,
    x: x_pos + Math.cos(((90 - bearing) * Math.PI) / 180) * (radius + 20),
    y: y_pos + Math.sin(((90 - bearing) * Math.PI) / 180) * (radius + 20),
    life: 20,
    r: 30,
    radius: 5,
    player: tank_id,
    particles,
  };
};

export const createHitEffect = (hit: IHit) => {
  const { x_pos, y_pos } = hit;
  const particles = createParticles();

  return {
    type: EffectType.HIT,
    x: x_pos,
    y: y_pos,
    life: 10,
    r: 20,
    radius: 3,
    particles,
    player: 0,
  };
};
