import * as React from "react";

interface TabBarProps {
  selected: number;
  setSelected: (i: number) => void;
}

const TabBar: React.FC<TabBarProps> = ({ children, selected, setSelected }) => {
  const childs = children as JSX.Element[] | undefined;
  const def = childs?.filter((c) => c);
  const width = `w-1/${def?.length}`;

  return (
    <div className="flex items-center w-full border-b-4 border-accent-script h-full max-h-12 transition ease-in">
      {def?.map((c, i) => {
        // changes tab if it is disabled and selected
        if (c.props.disabled && i === selected) setSelected(0);

        return React.cloneElement(c, {
          key: i,
          width,
          // can be selected(visible) only if its not disabled
          selected: selected === i && !c.props.disabled,
          setSelected,
          index: i,
        });
      })}
    </div>
  );
};

export default TabBar;
