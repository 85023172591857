import { BeakerIcon, CheckCircleIcon, ClockIcon, CubeTransparentIcon, UserGroupIcon } from "@heroicons/react/outline";
import React, { useState } from "react";
import FormStep from "src/components/FormStep";
import Input from "src/components/Input";
import MultiStepForm from "src/components/MultiStepForm";
import SwardsIcon from "src/icons/SwardsIcon";
import Button from "../../../components/Button";
import { useIsHostStore } from "src/global-stores/useIsHostStore";
import { client } from "../../ws/WSWrapper";
import AdaptiveGrid from "src/components/AdaptiveGrid";

interface LobbySelectionProps {}

interface IGameCreation{
  game_mode: number;
  map: number;
  settings: ISettings;
}

interface ISettings{
  preparation: number;
  players: number;
  skillpoints: number;
  ticks: number;
  survivalTicks: number;
}

const gameModes = [
  { label: "FFA Deathmatch", icon: <SwardsIcon className="w-14 h-14" /> },
];

const gameMaps = [
  { icon: <CubeTransparentIcon className="w-10 h-10"/>, label: "Standard (5x5)" },
  { icon: <CubeTransparentIcon className="w-10 h-10"/>, label: "Quick Draw (3x3)" },
  { icon: <CubeTransparentIcon className="w-10 h-10"/>, label: "Peekaboo (6x5)" },
  { icon: <CubeTransparentIcon className="w-10 h-10"/>, label: "Spiral (6x6)" },
  { icon: <CubeTransparentIcon className="w-10 h-10"/>, label: "Bounce (7x7)" },
  { icon: <CubeTransparentIcon className="w-10 h-10"/>, label: "Void (8x8)" },
  { icon: <CubeTransparentIcon className="w-10 h-10"/>, label: "Corners (7x6)" },
  { icon: <CubeTransparentIcon className="w-10 h-10"/>, label: "Clover (10x10)" },
  { icon: <CubeTransparentIcon className="w-10 h-10"/>, label: "Pillars (5x5)" },
  { icon: <CubeTransparentIcon className="w-10 h-10"/>, label: "Maze (10x10)" },
  { icon: <CubeTransparentIcon className="w-10 h-10"/>, label: "Rabbit (10x10)" },
  { icon: <CubeTransparentIcon className="w-10 h-10"/>, label: "Showdown (8x3)" }
];

const LobbySelection: React.FC<LobbySelectionProps> = ({}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inviteCode, setInviteCode] = useState("");
  const [error, setError] = useState("");
  const [gameCreation, setGameCreation] = useState<IGameCreation>({ game_mode: 0, map: 0, settings: { preparation: 120, players: 4, skillpoints: 8, ticks: 1500, survivalTicks: 50 } });
  const setIsHost = useIsHostStore((s) => s.setIsHost);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newinviteCode = e.target.value.toUpperCase();

    if (
      newinviteCode.length > 0 &&
      (!newinviteCode.match(/^[A-F0-9]+$/) || newinviteCode.length < 5)
    )
      setError(" ");
    else {
      setError("");
    }

    if (newinviteCode.length <= 5) setInviteCode(newinviteCode);
    else setInviteCode(newinviteCode.slice(0, 5));
  };

  const toMinutes = (seconds: number) => {
    if (seconds < 60) return seconds + " seconds";
    else {
      const rest = seconds % 60;
      // console.log(rest);
      const minutes = (seconds - rest) / 60;
      return (
        minutes +
        ` minute${minutes > 1 ? `s` : ``} ` +
        (rest > 0
          ? (rest > 60 ? rest * 60 : rest) + ` second${rest > 1 ? `s` : ``} `
          : "")
      );
    }
  };

  return (
    <React.Fragment>
      <div className="flex flex-col items-center justify-center h-full w-full space-y-2">
        <Button
          label="Create new game"
          rounded
          textColorLight="primary"
          onClick={() => setIsOpen(true)}
        />
        <span className="text-gray-300 dark:text-primary">Or</span>
        <form className="flex items-center space-x-4">
          <Input
            type="text"
            placeholder="Enter invite code"
            value={inviteCode}
            onChange={handleChange}
            autoComplete="off"
            error={error}
          />
          {!error && inviteCode.length > 0 && (
            <Button
              type="submit"
              disabled={!!error}
              label="join"
              rounded
              bgColorDark="green"
              bgColorLight="green"
              textColorLight="primary"
              onClick={(e) => {
                e.preventDefault();
                client.send(
                  JSON.stringify({ type: "join", invite: inviteCode })
                );
                setIsHost(false);
              }}
            />
          )}
        </form>
      </div>
      {isOpen && (
        <div
          className="absolute top-0 left-0 right-0 bottom-0 w-full h-full z-20 flex items-center justify-center"
          style={{ backgroundColor: "rgba(0,0,0,0.4)" }}
        >
          <div className="w-full h-full md:w-3/5 md:h-3/5 xl:w-2/5 xl:h-4/5 bg-primary dark:bg-gray-500 rounded-xl">
            <MultiStepForm
              values={gameCreation}
              handlesSubmit={() => {
                client.send(
                  JSON.stringify({
                    type: "setup",
                    mapCode: gameCreation.map,
                    prepareSeconds: gameCreation.settings.preparation,
                    maxPlayers: gameCreation.settings.players,
                    skillPoints: gameCreation.settings.skillpoints,
                    maxTicks: gameCreation.settings.ticks,
                    survivalTicks: gameCreation.settings.survivalTicks
                  })
                );
                setIsOpen(false);
                setIsHost(true);
              }}
              setSnapshot={setGameCreation}
              handleCloseModal={() => setIsOpen(false)}
            >
              <FormStep title="Mode" description="How do you want to battle?">
                {gameModes.map((m, i) => (
                  <button
                    key={i}
                    className="relative w-40 p-4 bg-gray-100 dark:bg-gray-400 dark:text-primary flex flex-col items-center space-y-2 rounded-lg shadow-lg"
                    onClick={() =>
                      setGameCreation((g) => {
                        return { ...g, game_mode: i };
                      })
                    }
                  >
                    {i === gameCreation.game_mode && (
                      <CheckCircleIcon className="w-6 h-6 absolute top-2 right-2 text-secondary-green-light" />
                    )}
                    <div className="text-gray-300">{m.icon}</div>
                    <div>{m.label}</div>
                  </button>
                ))}
              </FormStep>
              <FormStep title="Map" description="Choose your battleground">
                <div className="h-full flex flex-col w-full">
                <AdaptiveGrid childWidth={160} gap={20}>
                {gameMaps.map((m, i) => (
                  <button
                    key={i}
                    className="relative w-40 p-4 bg-gray-100 dark:bg-gray-400 dark:text-primary flex flex-col items-center space-y-2 rounded-lg shadow-lg"
                    onClick={() =>
                      setGameCreation((g) => {
                        return { ...g, map: i };
                      })
                    }
                  >
                    <div className="relative w-full h-full flex flex-col items-center space-y-2">
                      {i === gameCreation.map && (
                        <CheckCircleIcon className="w-4 h-4 absolute top-4 right-0 text-secondary-green-light" />
                      )}
                      <div className="text-gray-300">{m.icon}</div>
                      <div>{m.label}</div>
                    </div>
                  </button>
                ))}
                </AdaptiveGrid>
                <div className="w-full flex items-center justify-center text-monochrome-gray-600 dark:text-primary">
                  Or
                </div>
                </div>
                <Input type="text" placeholder="Enter map code" />
              </FormStep>
              <FormStep
                title="Settings"
                description="Customise your game settings"
              >
                <div className="flex items-center space-x-4 w-full text-gray-500 dark:text-primary p-2 border border-gray-100 rounded-lg">
                  <ClockIcon className="w-7 h-7" />
                  <div className="w-full space-y-3">
                    <div className="flex items-center justify-between w-full">
                      <h3 className="text-lg">Preparation Time</h3>
                      <div className="flex items-center space-x-2">
                        <span className="text-sm">
                          {toMinutes(gameCreation.settings.preparation)}
                        </span>
                        <input
                          className="cursor-pointer"
                          type="range"
                          value={gameCreation.settings.preparation}
                          onChange={(e) =>
                            setGameCreation((g) => {
                              return {
                                ...g,
                                settings: {
                                  ...g.settings,
                                  preparation: parseInt(e.target.value),
                                },
                              };
                            })
                          }
                          step={30}
                          min={30}
                          max={60 * 5}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center space-x-4 w-full text-gray-500 dark:text-primary p-2 border border-gray-100 rounded-lg">
                  <BeakerIcon className="w-7 h-7" />
                  <div className="w-full space-y-3">
                    <div className="flex items-center justify-between w-full">
                      <h3 className="text-lg">Max Skillpoints</h3>
                      <div className="flex items-center space-x-2">
                        <span className="text-sm">
                          {gameCreation.settings.skillpoints}
                        </span>
                        <input
                          className="cursor-pointer"
                          type="range"
                          value={gameCreation.settings.skillpoints}
                          onChange={(e) =>
                            setGameCreation((g) => {
                              return {
                                ...g,
                                settings: {
                                  ...g.settings,
                                  skillpoints: parseInt(e.target.value)
                                },
                              };
                            })
                          }
                          step={1}
                          min={0}
                          max={16}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center space-x-4 w-full text-gray-500 dark:text-primary p-2 border border-gray-100 rounded-lg">
                  <UserGroupIcon className="w-7 h-7" />
                  <div className="w-full space-y-3">
                    <div className="flex items-center justify-between w-full">
                      <h3 className="text-lg">Max Players</h3>
                      <div className="flex items-center space-x-2">
                        <span className="text-sm">
                          {gameCreation.settings.players}
                        </span>
                        <input
                          className="cursor-pointer"
                          type="range"
                          value={gameCreation.settings.players}
                          onChange={(e) =>
                            setGameCreation((g) => {
                              return {
                                ...g,
                                settings: {
                                  ...g.settings,
                                  players: parseInt(e.target.value)
                                },
                              };
                            })
                          }
                          step={1}
                          min={2}
                          max={8}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center space-x-4 w-full text-gray-500 dark:text-primary p-2 border border-gray-100 rounded-lg">
                  <UserGroupIcon className="w-7 h-7" />
                  <div className="w-full space-y-3">
                    <div className="flex items-center justify-between w-full">
                      <h3 className="text-lg">Game Length (24 ticks/ sec)</h3>
                      <div className="flex items-center space-x-2">
                        <span className="text-sm">
                          {gameCreation.settings.ticks}
                        </span>
                        <input
                          className="cursor-pointer"
                          type="range"
                          value={gameCreation.settings.ticks}
                          onChange={(e) =>
                            setGameCreation((g) => {
                              return {
                                ...g,
                                settings: {
                                  ...g.settings,
                                  ticks: parseInt(e.target.value)
                                },
                              };
                            })
                          }
                          step={250}
                          min={1000}
                          max={3000}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center space-x-4 w-full text-gray-500 dark:text-primary p-2 border border-gray-100 rounded-lg">
                  <UserGroupIcon className="w-7 h-7" />
                  <div className="w-full space-y-3">
                    <div className="flex items-center justify-between w-full">
                      <h3 className="text-lg">Survival Ticks (24 ticks/ sec)</h3>
                      <div className="flex items-center space-x-2">
                        <span className="text-sm">
                          {gameCreation.settings.survivalTicks}
                        </span>
                        <input
                          className="cursor-pointer"
                          type="range"
                          value={gameCreation.settings.survivalTicks}
                          onChange={(e) =>
                            setGameCreation((g) => {
                              return {
                                ...g,
                                settings: {
                                  ...g.settings,
                                  survivalTicks: parseInt(e.target.value)
                                },
                              };
                            })
                          }
                          step={25}
                          min={25}
                          max={100}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </FormStep>
            </MultiStepForm>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default LobbySelection;
