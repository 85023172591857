import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import HelpTabMD from "../../../../docs/HelpTab.md";
import TabWrapper from "../../../../components/TabWrapper";
import CodeBlock from "src/components/CodeBlock";
import * as remarkGfm from "remark-gfm";

interface HelpTabProps {}

const HelpTab: React.FC<HelpTabProps> = ({}) => {
  const [markdown, setMarkdown] = useState("");

  useEffect(() => {
    console.log('Help tab rendered');
  });

  useEffect(() => {
    fetch(HelpTabMD)
      .then((res) => res.text())
      .then((text) => setMarkdown(text));
  }, []);

  return (
    <TabWrapper>
      <ReactMarkdown
        className="prose dark:prose-dark"
        children={markdown}
        components={{
          code({ node, inline, className, children, ...props }) {
            const match = /language-(\w+)/.exec(className || "");
            return !inline && match ? (
              <CodeBlock
                language={match[1]}
                value={String(children).replace(/\n$/, "")}
                {...props}
              />
            ) : (
              <code className={className} {...props}>
                {children}
              </code>
            );
          },
        }}
        remarkPlugins={[remarkGfm as any]}
      />
    </TabWrapper>
  );
};

export default React.memo(HelpTab);
