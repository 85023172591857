import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationIcon,
  InformationCircleIcon,
  XIcon,
} from "@heroicons/react/outline";
import React, { useEffect } from "react";
import Button from "src/components/Button";
import { IToast } from "src/global-stores/useToastsStore";

interface ToastProps extends Omit<IToast, "id"> {
  onClose?: () => void;
  button?: React.ReactNode;
}

const Toast: React.FC<ToastProps> = ({
  message,
  duration,
  onClose,
  button,
  type,
}) => {
  useEffect(() => {
    if (duration === "sticky") return;

    const timerId = setTimeout(() => {
      onClose?.();
    }, duration);

    return () => clearTimeout(timerId);
  }, [duration]);

  let bgClass = "";
  let textCLass = "";
  let icon;

  if (type === "error") {
    bgClass = "bg-secondary-red-light";
    textCLass = "text-primary";
    icon = <ExclamationCircleIcon className="w-6 h-6" />;
  } else if (type === "warning") {
    bgClass = "bg-secondary-ocra-dark";
    textCLass = "text-primary";
    icon = <ExclamationIcon className="w-6 h-6" />;
  } else if (type === "success") {
    bgClass = "bg-secondary-green-light";
    textCLass = "text-primary";
    icon = <CheckCircleIcon className="w-6 h-6" />;
  } else {
    bgClass = "bg-primary dark:bg-gray-400";
    textCLass = "text-gray-400 dark:text-primary";
    icon = <InformationCircleIcon className="w-6 h-6" />;
  }

  return (
    <div
      className={`${bgClass} ${textCLass} shadow-lg flex rounded-lg p-3 relative w-full items-center justify-center transition-transform duration-300`}
    >
      {onClose && (
        <div
          className={`absolute`}
          style={{
            top: 5,
            right: 7,
          }}
        >
          <Button
            onClick={onClose}
            transparent
            padding={false}
            icon={<XIcon className="w-4 h-4" />}
          />
        </div>
      )}
      <div
        className={`flex items-center justify-start space-x-4 w-full h-full`}
      >
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center space-x-4">
            <div>{icon}</div>
            <span className={`flex flex-col items-start justify-center`}>
              {message.split("\n").map((t, i) => <span key={i}>{t}</span>)}
            </span>
          </div>
          <div className="mr-10">{button}</div>
        </div>
      </div>
    </div>
  );
};

export default Toast;
