import React from "react";

interface InputProps extends React.ComponentPropsWithoutRef<"input"> {
  error?: string;
}

const Input: React.FC<InputProps> = ({
  type,
  placeholder,
  value,
  onChange,
  error,
  className,
}) => {
  const cs = `${className} px-4 py-2 rounded-md border-2 ${
    error ? `border-secondary-red-light` : `border-gray-200`
  } focus:outline-none placeholder-gray-200 text-gray-300 dark:text-primary bg-primary dark:bg-gray-500`;

  return (
    <div className="flex flex-col">
      <input
        type={type}
        placeholder={placeholder}
        className={cs}
        value={value}
        onChange={onChange}
        autoComplete="off"
      />
      {error && (
        <span className="text-secondary-red-light text-sm">{error}</span>
      )}
    </div>
  );
};

export default Input;
