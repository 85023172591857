import React from "react";
import { useToastStore } from "src/global-stores/useToastsStore";
import Toast from "./Toast";

interface ToastControllerProps {}

const ToastController: React.FC<ToastControllerProps> = ({}) => {
  const { toasts, hideToast } = useToastStore();

  return (
    <div className="fixed bottom-0 left-2 right-2" style={{ zIndex: 1000 }}>
      {toasts.map((t) => (
        <div key={t.id} className="mb-3">
          <Toast
            type={t.type}
            message={t.message}
            duration={t.duration}
            onClose={() => hideToast(t.id)}
            button={t.button}
          />
        </div>
      ))}
    </div>
  );
};

export default ToastController;
