import React from "react";

interface BulletProps {
  x_pos: number;
  y_pos: number;
  radius: number;
  scale: number;
}

const Bullet: React.FC<BulletProps> = ({ x_pos, y_pos, radius, scale }) => {
  const scaledRadius = radius * scale;
  const scaledXPos = x_pos * scale;
  const scaledYPos = y_pos * scale;
  return (
    <svg
      width={scaledRadius * 2}
      height={scaledRadius * 2}
      viewBox={`0 0 ${scaledRadius * 2} ${scaledRadius * 2}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        position: "absolute",
        left: scaledXPos - scaledRadius,
        bottom: scaledYPos - scaledRadius,
      }}
      className="text-monochrome-black dark:text-primary"
    >
      <circle
        cx={scaledRadius}
        cy={scaledRadius}
        r={scaledRadius}
        fill="currentColor"
      />
    </svg>
  );
};

export default Bullet;
