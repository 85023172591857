// import { AppState } from "src/App";
import create from "zustand";
import { persist } from "zustand/middleware";

export enum Theme {
  light = "light",
  dark = "dark",
}

interface ThemeState {
  currentTheme: Theme;
  toggleTheme: () => void;
  setTheme: (theme: Theme) => void;
}

export const useThemeStore = create<ThemeState>(
  persist(
    (set, get) => ({
      currentTheme: Theme.light,
      toggleTheme: () =>
        set((s) => ({
          currentTheme:
            s.currentTheme === Theme.dark ? Theme.light : Theme.dark,
        })),
      setTheme: (t) => () => set((s) => ({ currentTheme: t })),
    }),
    {
      name: "theme-storage",
    }
  )
);
