/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { IGameUpdate } from "../../../interfaces/IGame";
import { IGame } from "src/interfaces/IGame";
import Bullet from "./game-components/Bullet";
import Effect from "./game-components/Effect";
import Tank from "./game-components/Tank";
import Wall from "./game-components/Wall";
import LineV from "./game-components/LineV";
import LineH from "./game-components/LineH";
import { IEffect } from "src/interfaces/IEffect";
import { Spinner } from "src/components/Spinner";
import { useGameInfoStore } from "src/global-stores/useGameInfoStore";

interface BattleFieldProps {
  startState?: IGame;
  currentState?: IGameUpdate;
  effects: IEffect[];
  scale?: number;
}

const BattleField: React.FC<BattleFieldProps> = ({
  currentState,
  startState,
  effects,
  scale = 0.75,
}) => {
  const border = 5;
  const { players, gameInfo } = useGameInfoStore();

  if (!startState)
    return (
      <div className="flex flex-col items-center justify-center h-full text-gray-200 space-y-2">
        <Spinner />
        <div>Loading board...</div>
      </div>
    );

  const walls = startState.map.walls.map((w, i) => (
    <Wall
      key={i}
      width={w.width}
      height={w.height}
      x_pos={w.x_pos}
      y_pos={w.y_pos}
      className="text-monochrome-black dark:text-primary z-10"
      scale={scale}
    />
  ));

  const tanks = currentState ? currentState.map.tanks : startState.map.tanks;
  let ts = [];
  for (let id in tanks) {
    if (id) {
      const ps = players.filter((p) => p.slot === parseInt(id));
      let colour;
      if (ps.length > 0) colour = gameInfo?.colours[ps[0].colour].code;

      ts.push(
        <Tank
          key={id}
          x_pos={tanks[id].x_pos * scale}
          y_pos={tanks[id].y_pos * scale}
          radius={startState.map.tanks[id].radius * scale}
          color={colour || "#000000"}
          heatPercent={
            (100 * tanks[id].heat) / (currentState?.map.tanks[id].max_heat || 1)
          }
          healthPercent={(100 * tanks[id].hp) / startState.map.tanks[id].max_hp}
          bearing={tanks[id].bearing}
        />
      );
    }
  }

  const bullets = currentState
    ? currentState.map.bullets
    : startState.map.bullets;
  let bs = [];

  for (let id in bullets)
    if (id)
      bs.push(
        <Bullet
          key={id}
          x_pos={bullets[id].x_pos}
          y_pos={bullets[id].y_pos}
          radius={bullets[id].radius || 5}
          scale={scale}
        />
      );

  let lines = [];
  let id = 0;
  for (
    let x = 100 * scale;
    x < startState.map.width * scale;
    x += 100 * scale
  ) {
    lines.push(
      <LineV key={id} x1={x} x2={x} y1={0} y2={startState.map.height * scale} />
    );
    id++;
  }

  let lines2 = [];
  id = 0;
  for (
    let y = 100 * scale;
    y < startState.map.height * scale;
    y += 100 * scale
  ) {
    lines2.push(
      <LineH key={id} x1={0} x2={startState.map.width * scale} y1={y} y2={y} />
    );
    id++;
  }

  const effs = effects.map((e, i) => (
    <Effect
      width={startState.map.width}
      height={startState.map.height}
      key={i}
      effect={e}
      scale={scale}
    />
  ));

  return (
    <div
      className={`relative border-${border} border-monochrome-black dark:border-primary`}
      style={{
        width: startState.map.width * scale + border * 2,
        height: startState.map.height * scale + border * 2,
      }}
    >
      {lines}
      {lines2}
      {walls}
      {ts}
      {bs}
      {effs}
    </div>
  );
};

export default BattleField;
