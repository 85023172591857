import { MenuIcon, XIcon } from "@heroicons/react/outline";
import {
  HeartIcon,
  MoonIcon,
  PlayIcon,
  PresentationChartLineIcon,
  StarIcon,
  SunIcon,
} from "@heroicons/react/solid";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Theme } from "src/global-stores/useThemeStore";
import { useScreenType } from "src/hooks/useScreenType";
import { useTheme } from "src/hooks/useTheme";
import logo from "../img/logo.png";
import icon from "../img/icon.png";
import Button from "./Button";
import IconLink from "./IconLink";
import Toggle from "./Toggle";

interface NavBarProps {}

const links = [
  <IconLink
    key={0}
    to="#"
    label="Play"
    icon={<PlayIcon className="w-8 h-8 text-accent-script" />}
    textColorLight="gray-500"
    textSize="big"
  />,
  <IconLink
    key={1}
    to="#"
    label="Learn"
    icon={<StarIcon className="w-8 h-8 text-accent-script" />}
    textColorLight="gray-500"
    textSize="big"
  />,
  <IconLink
    key={2}
    to="#"
    label="Stats"
    icon={<PresentationChartLineIcon className="w-7 h-7 text-accent-script" />}
    textColorLight="gray-500"
    textSize="big"
  />,
];

const NavBar: React.FC<NavBarProps> = ({}) => {
  const [theme, toggleTheme] = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  const screenType = useScreenType();

  const checked = theme === Theme.light;

  const toggleOpen = () => setIsOpen((s) => !s);

  const actions = [
    <Toggle
      key={0}
      startIcon={
        <SunIcon className="w-5 h-5 text-primary dark:text-gray-500" />
      }
      endIcon={<MoonIcon className="w-5 h-5 text-primary dark:text-gray-500" />}
      handleClick={toggleTheme}
      checked={checked}
    />,
    <Button
      key={1}
      label="Support"
      rounded
      icon={<HeartIcon className="w-5 h-5" />}
      textColorLight="primary"
      textColorDark="primary"
    />,
  ];

  return (
    <React.Fragment>
      <div className="bg-primary dark:bg-gray-400 p-2 h-14 w-full flex items-center justify-between shadow-md px-4 z-10">
        <div className="flex items-center space-x-4 w-full">
          <Link to="/">
            <img src={screenType === "1-cols" ? logo : icon} className="h-12" />
          </Link>
          {screenType === "3-cols" && (
            <div className="grid grid-cols-3 justify-items-start items-center justify-between w-1/2">
              {links.map((l) => l)}
            </div>
          )}
        </div>
        {screenType === "3-cols" ? (
          <div className="flex items-center space-x-8">
            {actions.map((a) => a)}
          </div>
        ) : (
          <div className="flex items-center space-x-6">
            <Toggle
              key={0}
              startIcon={
                <SunIcon className="w-5 h-5 text-primary dark:text-gray-500" />
              }
              endIcon={
                <MoonIcon className="w-5 h-5 text-primary dark:text-gray-500" />
              }
              handleClick={toggleTheme}
              checked={checked}
            />
            <Button
              transparent
              icon={
                isOpen ? (
                  <XIcon className="w-6 h-6" />
                ) : (
                  <MenuIcon className="w-6 h-6" />
                )
              }
              rounded
              className="border"
              onClick={toggleOpen}
            />
          </div>
        )}
      </div>
      {isOpen && (
        <div className="flex items-center justify-center absolute top-14 h-full w-full bg-primary dark:bg-gray-500 z-10">
          <div className="flex flex-col items-start justify-center space-y-14">
            <IconLink
              to="#"
              label="Play"
              icon={<PlayIcon className="w-20 h-20 text-accent-script" />}
              textColorLight="gray-500"
              textSize="text-5xl"
            />
            <IconLink
              to="#"
              label="Learn"
              icon={<StarIcon className="w-20 h-20 text-accent-script" />}
              textColorLight="gray-500"
              textSize="text-5xl"
            />
            <IconLink
              to="#"
              label="Stats"
              icon={
                <PresentationChartLineIcon className="w-20 h-20 text-accent-script" />
              }
              textColorLight="gray-500"
              textSize="text-5xl"
            />
            <IconLink
              to="#"
              label="Support"
              icon={<HeartIcon className="w-20 h-20 text-accent-script" />}
              textColorLight="gray-500"
              textSize="text-5xl"
            />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default NavBar;
