import { ReactElement, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "src/components/Button";
import { useFromCreatePageStore } from "src/global-stores/useFromCreatePageStore";
import { useGameBoardStore } from "src/global-stores/useGameBoardStore";
import { useGameInfoStore } from "src/global-stores/useGameInfoStore";
import { useIsHostStore } from "src/global-stores/useIsHostStore";
import { useToastStore } from "src/global-stores/useToastsStore";
import { EffectType, IEffect } from "src/interfaces/IEffect";
import { IGameState, IGameUpdate } from "src/interfaces/IGame";
import { ILobby } from "src/interfaces/ILobby";
import { createHitEffect, createShotEffect } from "src/util/effects.util";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { IError, IErrorReason } from "../../interfaces/IError";

interface WSRapperProps {
  children: ReactElement;
}

export const client = new W3CWebSocket(
  "wss://scriptanks.federicocecchinato.com"
);

const errorTexts = [
  "There are too many players",
  "The lobby is full",
  "That colour is taken",
  "Too many skill points used",
  "That game has already started",
  "That JSON has an invalid format",
  "Attribute not found",
  "Invalid Invite",
  "Player not in session",
  "You are not the host",
  "Need more players to start",
  "Invalid Value",
];

const WSWrapper: React.FC<WSRapperProps> = ({ children }) => {
  const [queue, setQueue] = useState<IGameUpdate[]>([]);
  const [currentTick, setCurrentTick] = useState(-1);
  const { setIsHost } = useIsHostStore();

  const {
    start: startGameBoard,
    current: currentGameBoard,
    setEffects,
    setGameBoardStartState,
    setGameBoardCurrentState,
    setGameBoardEndState,
    reset: resetGameBoardStore,
  } = useGameBoardStore();

  const {
    // gameInfo,
    setGameInfo,
    addPlayer,
    removePlayer,
    setGameState,
    setPlayerReady,
    setPlayerColour,
    reset: resetGameInfoStore,
  } = useGameInfoStore();

  const { showToast } = useToastStore();
  const { push } = useHistory();
  const { isFromCreatePage, setIsFromCreatePage } = useFromCreatePageStore();

  useEffect(() => {
    let tmpGameInfo: ILobby;

    client.onopen = () => {
      console.log("connection is open!");
      if (!isFromCreatePage) {
        client.send(
          JSON.stringify({
            type: "join",
            invite: window.location.href.substring(
              window.location.href.lastIndexOf("/") + 1
            ),
          })
        );
      }
    };

    client.onclose = () => {
      console.log("connection is closed!");
    };

    client.onerror = (error) => {
      console.error(error);
      showToast({
        message:
          "Connection could not be established... Try refreshing the page",
        duration: "sticky",
        type: "error",
        button: (
          <Button
            label="Refresh page"
            onClick={() => window.location.reload()}
            transparent
          />
        ),
      });
    };

    client.onmessage = (message) => {
      const data = JSON.parse(message.data as string);

      if (
        data.state === IGameState.LOBBY ||
        data.state === IGameState.START ||
        data.state === IGameState.PLAY ||
        data.state === IGameState.END
      ) {
        setGameState(data.state);
      }

      if (data.state === IGameState.LOBBY) {
        console.log("Lobby: ", data);
        tmpGameInfo = data;
        setCurrentTick(-1);
        setQueue([]);
        resetGameInfoStore();
        resetGameBoardStore(); // this should fix
        setGameInfo(data);
        if (data.yourSlot === 0) {
          setIsHost(true);
        } else {
          setIsHost(false);
        }
        // redirect to game page if invite was correct or the game was created succesfully
        setIsFromCreatePage(true);
        push(`/game/${data.invite}`);
      } else if (data.state === IGameState.JOIN) {
        console.log("join: ", data);
        addPlayer(data);
        // setPlayerColour(data.colour, data.slot);
      } else if (data.state === IGameState.COLOUR) {
        console.log("Colour", data);
        setPlayerColour(data.colour, data.slot);
      } else if (data.state === IGameState.READY) {
        // console.log("Ready outside if: ", data.slot, tmpGameInfo?.yourSlot);
        if (data.slot === tmpGameInfo?.yourSlot) {
          // console.log("Ready inside if: ", data.slot, tmpGameInfo?.yourSlot);
          showToast({
            type: "success",
            duration: 2000,
            message: "You are now ready for battle",
          });
        }
        setPlayerReady(data);
      } else if (data.state === IGameState.LEAVE) {
        removePlayer(data);
      } else if (data.state === IGameState.START) {
        console.log("Game started", data);
        setGameBoardStartState(data);
      } else if (data.state === IGameState.PLAY) {
        // console.log("play");
        setQueue((q) => [...q, data]);
        setCurrentTick((t) => (t += 1));
      } else if (data.state === IGameState.END) {
        console.log("Game ended: ", data);
        setGameBoardEndState(data);
      } else if (data.state === IGameState.SHUTDOWN) {
        resetGameInfoStore();
        resetGameBoardStore();
        setIsFromCreatePage(true);
        push("/");
      } else if (data.state === IGameState.ERROR) {
        console.log("Error: ", data);
        if ((data as IError).reason !== IErrorReason.ScriptError) {
          showToast({
            message: errorTexts[data.reason],
            type: "error",
            duration: 7000,
          });
        } else {
          // console.log("here", data.traceback);
          console.log(
            "new traceback: ",
            data.traceback.replace(/\r?\n|\r/g, "\n")
          );
          showToast({
            message: data.traceback.replace(/\r?\n|\r/g, "\n"),
            type: "error",
            duration: "sticky",
          });
        }
        // redirect to create game page if the invite was incorrect
        if (
          data.reason === IErrorReason.AlreadyStarted ||
          data.reason === IErrorReason.InvalidInvite
        )
          push("/");
      }
    };

    return () => {
      client.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentTick !== -1) {
      setGameBoardCurrentState(queue[currentTick]);
    } else {
      setGameBoardCurrentState(undefined);
    }
  }, [currentTick, queue, setGameBoardCurrentState]);

  useEffect(() => {
    // updating effects
    setEffects((es) => {
      es.map((e, i) => {
        if (e.life === 1) {
          es.splice(i, 1);
          i -= 1;
        } else {
          e.life -= 1;

          if (
            e.type === EffectType.SHOT &&
            currentGameBoard &&
            startGameBoard
          ) {
            const tank = currentGameBoard.map.tanks[e.player];
            if (!tank) {
              es.splice(i, 1);
              i -= 1;
            } else {
              e.x =
                tank.x_pos +
                Math.cos(((90 - tank.bearing) * Math.PI) / 180) *
                  (startGameBoard.map.tanks[e.player].radius + 20);
              e.y =
                tank.y_pos +
                Math.sin(((90 - tank.bearing) * Math.PI) / 180) *
                  (startGameBoard.map.tanks[e.player].radius + 20);
            }
          }
        }
      });
      return es;
    });

    // adding all the hits effetcs
    if (currentGameBoard && currentGameBoard.map.hits.length > 0) {
      let newEffects: IEffect[] = [];
      currentGameBoard.map.hits.map((h) => {
        newEffects.push(createHitEffect(h));
      });
      setEffects((es) => [...es, ...newEffects]);
    }

    // adding all the shot effects
    if (currentGameBoard) {
      for (let id in currentGameBoard.map.tanks) {
        if (id) {
          if (currentGameBoard.map.tanks[id].just_fired && startGameBoard) {
            const { bearing, x_pos, y_pos } = currentGameBoard.map.tanks[id];
            const { radius } = startGameBoard.map.tanks[id];
            setEffects((e) => [
              ...e,
              createShotEffect({
                bearing,
                x_pos,
                y_pos,
                radius,
                tank_id: parseInt(id),
              }),
            ]);
          }
        }
      }
    }
  }, [currentGameBoard, startGameBoard]);

  return children;
};

export default WSWrapper;
