export const pythonSnippet = `import scriptanks
import random

class MyTankController(scriptanks.Controller):
  def __init__(self):
    super().__init__()
    self.is_my_tank_the_best = True  # Try setting to False :)

  # Called once, before the first tick.
  def on_start(self):
    
    if not self.is_my_tank_the_best:
        self.explode()

  # Called after every single tick.
  def on_tick(self):
    # If there are no commands queued up, move forwards
    if len(self.command_queue) == 0:
      self.move(100)

    # If we collide with a wall, turn in a random direction
    if self.tank.just_collided:
      self.override()  # Clears the command queue
      self.turn(random.randint(-180,180))

    # If we can see an enemy, shoot at them!
    if self.tank.tank_ahead and self.tank.heat == 0:  # Check for overheating...
      self.override()
      self.shoot()

t = MyTankController()
t.run()`;
