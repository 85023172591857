import { IReady } from "./../interfaces/IReady";
import { ILeave } from "./../interfaces/ILeave";
import { IJoin } from "./../interfaces/IJoin";
import { ILobby } from "./../interfaces/ILobby";
import { IGameState } from "src/interfaces/IGame";
import combine from "zustand";

interface GameInfoState {
  gameInfo?: ILobby;
  players: IJoin[];
  gameState: IGameState;
  setGameInfo: (gameInfo: ILobby) => void;
  addPlayer: (newPlayer: IJoin) => void;
  setPlayerReady: (player: IReady) => void;
  removePlayer: (toRemove: ILeave) => void;
  setGameState: (g: IGameState) => void;
  setPlayerColour: (i: number, slot: number) => void;
  reset: () => void;
}

export const useGameInfoStore = combine<GameInfoState>((set) => ({
  gameInfo: undefined,
  players: [],
  gameState: IGameState.NOT_IN_GAME,
  setGameInfo: (gameInfo) => set({ gameInfo }),
  addPlayer: (newPlayer) =>
    set((s) => ({ players: [...s.players, { ...newPlayer, ready: false }] })),
  setPlayerReady: (player: IReady) =>
    set((s) => ({
      players: [
        ...s.players.filter((p) => p.slot !== player.slot),
        {
          ...s.players.filter((p) => p.slot === player.slot)?.[0],
          ready: true,
        },
      ],
    })),
  removePlayer: (toRemove) =>
    set((s) => ({
      players: s.players.filter((p) => p.slot !== toRemove.slot),
    })),
  setGameState: (g) => set({ gameState: g }),
  setPlayerColour: (i, slot) =>
    set((s) => ({
      players: [
        ...s.players.filter((p) => p.slot !== slot),
        {
          ...s.players.filter((p) => p.slot === slot)?.[0],
          colour: i,
        },
      ],
    })),
  reset: () =>
    set({ players: [], gameInfo: undefined, gameState: IGameState.LOBBY }),
}));
