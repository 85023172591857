import { IValueOrCallBack } from "./../interfaces/IValueOrCallback";
import { IEffect } from "./../interfaces/IEffect";
import { IEndGame } from "./../interfaces/IEndGame";
import { IGame, IGameUpdate } from "./../interfaces/IGame";
import combine from "zustand";

interface GameBoardState {
  start?: IGame;
  current?: IGameUpdate;
  end?: IEndGame;
  effects: IEffect[];
  setGameBoardStartState: (start: IGame) => void;
  setGameBoardCurrentState: (current?: IGameUpdate) => void;
  setGameBoardEndState: (end: IEndGame) => void;
  setEffects: (effects: IValueOrCallBack<IEffect[]>) => void;
  reset: () => void;
}
export const useGameBoardStore = combine<GameBoardState>((set) => ({
  start: undefined,
  current: undefined,
  end: undefined,
  effects: [],
  setGameBoardStartState: (start) => set({ start }),
  setGameBoardCurrentState: (current) => set({ current }),
  setGameBoardEndState: (end) => set({ end }),
  setEffects: (e) =>
    set((s) => ({ effects: typeof e === "function" ? e(s.effects) : e })),
  reset: () =>
    set({ start: undefined, current: undefined, end: undefined, effects: [] }),
}));
