import { IMapData, IMapUpdate } from "./IMapData";

export enum IGameState {
  NOT_IN_GAME = -1,
  LOBBY = 0,
  JOIN = 1,
  LEAVE = 2,
  COLOUR = 3,
  ERROR = 4,
  START = 5,
  READY = 6,
  UNREADY = 7,
  PLAY = 8,
  END = 9,
  SHUTDOWN = 10,
}

export interface IGame {
  state: IGameState;
  start_response: number;
  tick_response: number;
  current_tick: number;
  max_ticks: number;
  survival_ticks: number;
  player_id: number;
  map: IMapData;
}

type IGameUpdatePartial = Pick<IGame, "current_tick" | "state">;

export type IGameUpdate = IGameUpdatePartial & { map: IMapUpdate };
