import React from "react";

interface LineVProps {
    x1: number;
    x2: number;
    y1: number;
    y2: number;
    className?: string;
}

const LineV: React.FC<LineVProps> = ({
    x1,
    x2,
    y1,
    y2,
    className,
}) => {
    return (
        <svg
            width={1}
            height={Math.abs(y1 - y2)}
            stroke="currentColor"
            className="text-gray-200 dark:text-gray-500"
            strokeWidth="2"
            viewBox={`0 0 ${1} ${Math.abs(y1 - y2)}`}
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            style={{
                position: "absolute",
                left: x1,
                bottom: y1,
            }}
        >
            <rect width={1} height={Math.abs(y1 - y2)} />
        </svg>

    );
};

export default LineV;
