import { useEffect } from "react";
import { Theme, useThemeStore } from "./../global-stores/useThemeStore";

export const useTheme = (): [Theme, () => void, (t: Theme) => void] => {
  const [theme, toggleTheme, setTheme] = useThemeStore((s) => [
    s.currentTheme,
    s.toggleTheme,
    s.setTheme,
  ]);
  const notTheme = theme === Theme.light ? Theme.dark : Theme.light;

  useEffect(() => {
    const root = window.document.documentElement;
    root.classList.remove(notTheme);
    root.classList.add(theme);
  }, [theme, notTheme]);

  return [theme, toggleTheme, setTheme];
};
