import React from "react";
import { IEndGame } from "src/interfaces/IEndGame";
import { IJoin } from "src/interfaces/IJoin";

interface EndGameProps {
  players: IJoin[];
  endGameBoard?: IEndGame;
}

const EndGame: React.FC<EndGameProps> = ({ players, endGameBoard }) => {
  if (!endGameBoard) return <div>Loading Winner...</div>;

  const getWinnerLabel = () => {
    if(endGameBoard.winners.length === 0) return "There is no winner";

    let first = true;
    let label = "The winner is ";
    for (let i in players) {
      if (endGameBoard.winners.includes(players[i].slot)) {
        if (first) label += players[i].username;
        else label += ", " + players[i].username;
        first = false;
      }
    }
    return label;
  };

  return (
    <div className="w-full h-5/6 flex items-center justify-center">
      <div className="flex flex-col items-center space-y-3 text-monochrome-gray-700 dark:text-monochrome-white">
        <h1 className="text-4xl ">Game over</h1>
        <h3 className="text-2xl">
          {getWinnerLabel()}
        </h3>
      </div>
    </div>
  );
};

export default EndGame;
