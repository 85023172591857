import combine from "zustand";
import { v4 } from "uuid";

type ToastDuration = "sticky" | number;
type ToastType = "error" | "warning" | "info" | "success";

export interface IToast {
  id: string;
  message: string;
  duration: ToastDuration;
  type: ToastType;
  button?: React.ReactNode;
}

interface ToastsState {
  toasts: IToast[];
  showToast: (newToast: Omit<IToast, "id">) => void;
  hideToast: (id: string) => void;
}

export const useToastStore = combine<ToastsState>((set) => ({
  toasts: [],
  showToast: (t) =>
    set((s) => ({
      toasts: [
        ...s.toasts.filter((y) => y.message !== t.message),
        { ...t, id: v4() },
      ],
    })),
  hideToast: (id) =>
    set((s) => ({
      toasts: [...s.toasts.filter((t) => t.id !== id)],
    })),
}));
