import React from "react";

interface PropgressBarProps /* extends React.ComponentPropsWithoutRef<"svg"> */{
    percent: number;
    width: number;
    height: number;
    x: number;
    y: number;
    type: number;
    stroke: string;
    className?: string;
}

const getColorBasedOnPercent = (percentage: number, type: number) => {
    if (type === 1) {
        if (percentage <= 40) {
            return "#C81D1D"; // red
        } else if (percentage > 40 && percentage < 70) {
            return "#C8C11D"; // yellow
        } else {
            return "#29C11C"; // green
        }
    } else {
        return 'rgba(200, 29, 29,' + String(percentage / 100) + ')'; // red
    }
};

const ProgressBar: React.FC<PropgressBarProps> = ({
    width,
    height,
    percent,
    x,
    y,
    type,
    stroke,
    className = "",
    ...props
}) => {
    const color = getColorBasedOnPercent(percent, type);

    return (
        <svg
            width={width}
            height={height}
            viewBox={`-1 -1 ${width + 1} ${height + 1}`}
            x={x}
            y={y}
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            {...props}
        >
            <rect
                id="health_bar_fill"
                x={0} // body_x * 0,035714285714286
                y={0} // body_y * 0,064814814814815
                width={(width * percent) / 100} // heatlthbar_width * health_percent / 100
                height={height} // radius * 2 * 0,180555555555556
                fill={color}
                stroke="none"
            />
            <rect
                id="health_bar"
                x={0}
                y={0}
                width={width}
                height={height}
                strokeWidth="1"
                stroke="black"
                fill="none"
            />

        </svg>
    );
};

export default ProgressBar;
