export interface IEffect {
  type: EffectType;
  x: number;
  y: number;
  player: number;
  life: number;
  particles: IParticile[];
  r: number;
  radius: number;
}

export enum EffectType {
  SHOT = "shot",
  HIT = "hit",
}

export interface IParticile {
  dx: number;
  dy: number;
}
