import React from "react";

interface FormStepProps {
  title?: string;
  description?: string;
}

const FormStep: React.FC<FormStepProps> = ({
  title,
  description,
  children,
}) => {
  return (
    <div className="p-5 space-y-4">
      <div className="text-gray-500 dark:text-primary space-y-1">
        <h1 className="text-3xl font-bold">{title}</h1>
        <p className="font-light">{description}</p>
      </div>
      {children}
    </div>
  );
};

export default FormStep;
