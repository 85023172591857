import React from "react";

interface SwardiconProps extends React.ComponentPropsWithoutRef<"svg"> {}

const SwardsIcon: React.FC<SwardiconProps> = ({ ...props }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 512.32 512.32"
      fill="currentColor"
      {...props}
    >
      <g transform="translate(1 1)">
        <g>
          <path
            d="M43.516,499.373L11.943,467.8c-4.267-4.267-4.267-11.947,0-16.213l6.827-8.533
                c4.267-4.267,11.947-4.267,16.213,0l32.427,31.573c4.267,4.267,4.267,11.947,0,16.213l-7.68,7.68
                C55.463,503.64,47.783,503.64,43.516,499.373"
          />
          <path
            d="M148.476,361.987L148.476,361.987c-14.507-14.507-23.893-32.427-27.307-52.053
                c-1.707-11.093,5.973-21.333,17.067-20.48c7.68,0,14.507,5.973,16.213,13.653c2.56,12.8,8.533,24.747,17.92,34.133
                c10.24,10.24,22.187,16.213,35.84,18.773c7.68,1.707,13.653,8.533,13.653,16.213l0,0c0,10.24-9.387,18.773-19.627,17.067
                C182.609,386.733,162.983,377.347,148.476,361.987"
          />
          <path
            d="M467.623,499.373l31.573-31.573c4.267-4.267,4.267-11.947,0-16.213l-7.68-8.533
                c-4.267-4.267-11.947-4.267-16.213,0l-32.427,31.573c-4.267,4.267-4.267,11.947,0,16.213l7.68,7.68
                C455.676,503.64,462.503,503.64,467.623,499.373"
          />
          <path
            d="M361.809,361.987L361.809,361.987c14.507-14.507,23.893-32.427,27.307-52.053
                c1.707-11.093-5.973-21.333-17.067-20.48c-7.68,0-14.507,5.973-16.213,13.653c-2.56,12.8-8.533,24.747-17.92,34.133
                c-10.24,10.24-22.187,16.213-35.84,18.773c-7.68,1.707-13.653,8.533-13.653,16.213l0,0c0,10.24,9.387,18.773,19.627,17.067
                C328.529,386.733,347.303,377.347,361.809,361.987"
          />
        </g>
        <g>
          <polygon points="63.143,471.213 39.249,447.32 136.529,350.04 160.423,373.933" />
          <polygon points="447.143,471.213 471.036,447.32 373.756,350.04 349.863,373.933" />
          <path
            d="M337.916,338.093c-8.533,8.533-19.627,14.507-30.72,17.92L38.396,86.36L7.676,7.853l78.507,30.72
                l268.8,267.947C352.423,318.467,346.449,328.707,337.916,338.093"
          />
          <path
            d="M207.356,255.32l-51.2,51.2c2.56,11.947,8.533,22.187,17.067,30.72
                c8.533,8.533,19.627,14.507,30.72,17.92l52.053-52.053L207.356,255.32z"
          />
          <polygon points="424.103,38.573 255.143,206.68 303.783,255.32 471.889,86.36 502.609,7.853" />
        </g>
        <path
          d="M63.143,479.747c-2.56,0-4.267-0.853-5.973-2.56l-23.893-23.893c-3.413-3.413-3.413-8.533,0-11.947l97.28-97.28
            c1.707-1.707,3.413-2.56,5.973-2.56l0,0c2.56,0,4.267,0.853,5.973,2.56l23.893,23.893c3.413,3.413,3.413,8.533,0,11.947
            l-96.427,97.28C68.263,478.893,65.703,479.747,63.143,479.747z M51.196,447.32l11.947,11.947l85.333-85.333l-11.947-11.947
            L51.196,447.32z"
        />
        <path
          d="M51.196,511.32c-5.12,0-10.24-1.707-14.507-5.973l0,0L5.116,473.773c-3.413-3.413-5.973-8.533-5.973-14.507
            c0-5.12,1.707-10.24,5.973-14.507l7.68-7.68c7.68-7.68,20.48-7.68,28.16,0l32.427,31.573c3.413,3.413,5.973,8.533,5.973,14.507
            c0,5.12-1.707,10.24-5.973,14.507l-7.68,7.68C61.436,508.76,56.316,511.32,51.196,511.32z M49.489,493.4
            c0.853,0.853,2.56,0.853,4.267,0l7.68-7.68c0.853-0.853,0.853-1.707,0.853-2.56s0-1.707-0.853-2.56l-31.573-31.573
            c-0.853-0.853-3.413-0.853-4.267,0l-7.68,7.68c-1.707,0.853-1.707,1.707-1.707,2.56s0,1.707,0.853,2.56L49.489,493.4z"
        />
        <path
          d="M204.796,399.533c-1.707,0-2.56,0-4.267,0c-22.187-4.267-41.813-14.507-58.027-30.72l0,0c0,0,0,0-0.853-0.853
            c-15.36-15.36-25.6-34.987-29.867-56.32c-1.707-7.68,0.853-16.213,5.973-22.187c5.12-5.973,12.8-9.387,20.48-8.533
            c11.947,0,22.187,9.387,23.893,20.48c1.707,11.093,7.68,21.333,15.36,29.867c8.533,8.533,19.627,14.507,31.573,16.213
            c11.947,1.707,20.48,12.8,20.48,24.747c0,7.68-3.413,15.36-9.387,20.48C216.743,396.973,210.769,399.533,204.796,399.533z
             M154.449,356.013c12.8,12.8,30.72,22.187,48.64,25.6c3.413,0.853,5.12-0.853,6.827-1.707c1.707-1.707,3.413-4.267,3.413-6.827
            c0-4.267-2.56-7.68-6.827-8.533c-14.507-2.56-29.013-10.24-40.107-21.333c-11.093-11.093-17.92-24.747-20.48-39.253
            c-0.853-3.413-4.267-6.827-7.68-6.827s-5.973,1.707-6.827,2.56c-1.707,1.707-2.56,5.12-1.707,7.68
            C133.116,326.147,141.649,343.213,154.449,356.013l-5.973,5.973L154.449,356.013z"
        />
        <path
          d="M280.743,238.253c-2.56,0-4.267-0.853-5.973-2.56c-3.413-3.413-3.413-8.533,0-11.947l153.6-153.6
            c3.413-3.413,8.533-3.413,11.947,0c3.413,3.413,3.413,8.533,0,11.947l-153.6,153.6C285.009,237.4,283.303,238.253,280.743,238.253z
            "
        />
        <path
          d="M447.143,479.747c-2.56,0-4.267-0.853-5.973-2.56l-97.28-97.28c-3.413-3.413-3.413-8.533,0-11.947l23.893-23.893
            c3.413-3.413,8.533-3.413,11.947,0l97.28,97.28c3.413,3.413,3.413,8.533,0,11.947l-23.893,23.893
            C451.409,478.893,448.849,479.747,447.143,479.747z M361.809,373.933l85.333,85.333l11.947-11.947l-85.333-85.333L361.809,373.933z
            "
        />
        <path
          d="M459.089,511.32c-5.12,0-10.24-1.707-14.507-5.973l-7.68-7.68c-3.413-3.413-5.973-8.533-5.973-14.507
            c0-5.12,1.707-10.24,5.973-14.507l31.573-31.573c7.68-7.68,20.48-7.68,28.16,0l7.68,7.68c3.413,3.413,5.973,8.533,5.973,14.507
            c0,5.12-1.707,10.24-5.973,14.507l-31.573,31.573l0,0C469.329,508.76,464.209,511.32,459.089,511.32z M482.983,448.173
            c-0.853,0-1.707,0-2.56,0.853L448.849,480.6c-0.853,0.853-0.853,1.707-0.853,2.56c0,0.853,0,1.707,0.853,2.56l7.68,7.68
            c0.853,0.853,3.413,0.853,4.267,0l31.573-31.573c1.707-1.707,1.707-2.56,1.707-2.56c0-0.853,0-1.707-0.853-2.56l-7.68-7.68
            C484.689,448.173,483.836,448.173,482.983,448.173z"
        />
        <path
          d="M305.489,399.533c-5.973,0-11.947-1.707-16.213-5.973c-5.973-5.12-9.387-12.8-9.387-20.48
            c0-11.947,8.533-22.187,20.48-24.747c11.093-1.707,22.187-7.68,31.573-17.067c8.533-8.533,13.653-18.773,15.36-29.867
            c1.707-11.947,11.947-20.48,23.893-20.48c7.68,0,15.36,2.56,20.48,8.533c5.12,5.973,6.827,13.653,5.973,22.187
            c-4.267,21.333-14.507,40.96-29.013,56.32c0,0,0,0-0.853,0.853l0,0c-16.213,16.213-35.84,26.453-58.027,30.72
            C308.049,398.68,307.196,399.533,305.489,399.533z M372.903,297.987L372.903,297.987c-4.267,0-7.68,2.56-8.533,6.827
            c-2.56,14.507-9.387,28.16-20.48,39.253c-11.947,11.093-25.6,18.773-40.107,21.333c-3.413,0.853-6.827,4.267-6.827,7.68
            c0,2.56,0.853,5.12,3.413,6.827c0.853,0.853,3.413,2.56,6.827,1.707c18.773-3.413,35.84-12.8,48.64-25.6l0,0
            c12.8-13.653,21.333-29.867,24.747-47.787c0.853-2.56,0-5.12-1.707-7.68C378.023,299.693,376.316,297.987,372.903,297.987z"
        />
        <path
          d="M307.196,363.693c-2.56,0-4.267-0.853-5.973-2.56l-268.8-268.8c-0.853-0.853-1.707-1.707-1.707-2.56l-30.72-78.507
            C-0.857,7.853-0.857,4.44,1.703,1.88s5.973-3.413,9.387-1.707l78.507,30.72c0.853,0,2.56,0,3.413,0.853l268.8,268.8
            c1.707,1.707,2.56,5.12,2.56,7.68c-3.413,13.653-9.387,25.6-19.627,34.987l0,0c-10.24,9.387-21.333,16.213-34.133,19.627
            C308.903,363.693,308.049,363.693,307.196,363.693z M45.223,82.093l263.68,263.68c8.533-2.56,15.36-7.68,22.187-13.653
            c5.973-5.973,11.093-14.507,13.653-22.187L81.916,45.4l-58.88-23.04L45.223,82.093z"
        />
        <path
          d="M306.343,315.053c-2.56,0-4.267-0.853-5.973-2.56l-230.4-230.4c-3.413-3.413-3.413-8.533,0-11.947
            c3.413-3.413,8.533-3.413,11.947,0l230.4,230.4c3.413,3.413,3.413,8.533,0,11.947C310.609,314.2,308.903,315.053,306.343,315.053z"
        />
        <path
          d="M203.089,363.693c-0.853,0-1.707,0-2.56,0c-12.8-3.413-24.747-10.24-34.133-19.627
            c-9.387-10.24-16.213-22.187-19.627-34.987c-0.853-2.56,0-5.973,2.56-7.68l51.2-51.2c3.413-3.413,8.533-3.413,11.947,0l48.64,48.64
            c1.707,1.707,2.56,3.413,2.56,5.973s-0.853,4.267-2.56,5.973l-52.053,52.053C207.356,362.84,205.649,363.693,203.089,363.693z
             M164.689,309.08c2.56,8.533,7.68,16.213,13.653,22.187c6.827,5.973,13.653,11.093,22.187,13.653l42.667-42.667l-35.84-35.84
            L164.689,309.08z"
        />
        <path
          d="M303.783,263.853c-2.56,0-4.267-0.853-5.973-2.56l-48.64-48.64c-3.413-3.413-3.413-8.533,0-11.947l168.96-168.96
            c0.853-0.853,1.707-1.707,2.56-1.707l78.507-30.72c3.413-0.853,6.827,0,9.387,2.56c2.56,2.56,3.413,5.973,1.707,9.387
            l-30.72,78.507c0,0.853-0.853,1.707-1.707,2.56l-168.107,168.96C308.049,263,305.489,263.853,303.783,263.853z M267.089,206.68
            l35.84,35.84L465.063,82.093l23.04-58.88l-58.88,23.04L267.089,206.68z"
        />
      </g>
    </svg>
  );
};

export default SwardsIcon;
