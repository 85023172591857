import * as React from "react";
import Button from "./Button";
import Selector from "./Selector";
import { Language, useEditorStore } from "src/global-stores/useEditorStore";
import { CogIcon } from "@heroicons/react/solid";
import FileInput from "./FileInput";
import { UploadIcon } from "@heroicons/react/outline";

interface EditorInfoProps { }

const EditorInfo: React.FC<EditorInfoProps> = ({ }) => {
  const { language, cursorPosition, setLanguage, setContent } =
    useEditorStore();
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const file = event.target.files;

    if (!file) return;
    const fileReader = new FileReader();

    fileReader.onloadend = (_) => {
      const text = fileReader.result;
      setContent(text as string);
    };

    fileReader.readAsText(file[0]);
  };
  return (
    <div className="flex items-center justify-between px-4 py-2 border-t border-gray-100 dark:border-gray-500 bg-primary dark:bg-gray-500">
      <div className="flex items-center space-x-6">
        <Button
          icon={<CogIcon className="text-gray-200 w-6 h-6" />}
          transparent
          padding={false}
        />
        <div className="text-gray-200 space-x-3 flex items-center">
          <div>Language</div>
          <Selector<Language>
            list={Object.values(Language)}
            selected={language}
            setSelected={setLanguage}
          />
        </div>
        <FileInput onChange={handleFileChange} max={1} accept=".py,.java">
          <Button
            label="Upload file"
            icon={<UploadIcon className="w-5 h-5" />}
            transparent
            textColorLight="gray-200"
            textColorDark="gray-200"
            rounded
            padding={false}
          />
        </FileInput>
      </div>
      <div className="text-gray-200">
        Row: {cursorPosition.row} Col: {cursorPosition.column}
      </div>
    </div>
  );
};

export default EditorInfo;
