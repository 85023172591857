import { IEffect } from "src/interfaces/IEffect";

interface EffectProps {
  effect: IEffect;
  width: number;
  height: number;
  scale: number;
}

const Effect: React.FC<EffectProps> = ({ effect, width, height, scale }) => {
  const scaledWidth = width * scale;
  const scaledHeight = height * scale;
  const scaledX = effect.x * scale;
  const scaledY = effect.y * scale;

  const R = effect.r - effect.life;
  return (
    <svg
      width={scaledWidth}
      height={scaledHeight}
      viewBox={`0 0 ${scaledWidth} ${scaledHeight}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        position: "absolute",
        left: scaledX - scaledWidth / 2,
        bottom: scaledY - scaledHeight / 2,
      }}
    >
      {effect.particles.map((p, i) => (
        <circle
          key={i}
          cx={scaledWidth / 2 + p.dy * scale * R}
          cy={scaledHeight / 2 + p.dx * scale * R}
          r={effect.radius}
          fill={`rgba(255, ${
            (((p.dx * scale * R) ** 2 + (p.dy * scale * R) ** 2) / 100) * 150
          }, 0, ${effect.life / 10})`}
        />
      ))}
    </svg>
  );
};

export default Effect;
