import React from "react";
import { IColour } from "src/interfaces/IColour";
import AdaptiveGrid from "./AdaptiveGrid";
import ColorSelection from "./ColorSelection";

interface ColorPickerProps {
  colours?: IColour[];
  coloursWidth?: number;
  selected: number;
  setSelected: (i: number) => void;
  gap?: number;
  alreadySelectedColours: number[]
}

const ColorPicker: React.FC<ColorPickerProps> = ({
  colours,
  selected,
  setSelected,
  coloursWidth = 144,
  gap = 16,
  alreadySelectedColours
}) => {
  return (
   <AdaptiveGrid gap={gap} childWidth={coloursWidth}>
      {colours?.map((c, i) => (
        <ColorSelection
          key={i}
          colour={c}
          selected={i === selected}
          onClick={() => setSelected(i)}
          alreadySelected={alreadySelectedColours.includes(i)}
        />
      ))}
    </AdaptiveGrid>
  );
};

export default ColorPicker;
