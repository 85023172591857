import combine from 'zustand';

interface FromCreatePageState {
    isFromCreatePage: boolean;
    setIsFromCreatePage: (b: boolean) => void
}

export const useFromCreatePageStore = combine<FromCreatePageState>((set) => ({
    isFromCreatePage: !(window.location.pathname === `/game/${window.location.href.substring(window.location.href.lastIndexOf('/') + 1)}`),
    setIsFromCreatePage: (b) => set({ isFromCreatePage: b })
}));
