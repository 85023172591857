import React from "react";
import { useGameInfoStore } from "src/global-stores/useGameInfoStore";
import { useTankSpecsStore } from "src/global-stores/useTankSpecsStore";
import ColorPicker from "../../../../components/ColorPicker";
import Slider from "../../../../components/Slider";
import TabWrapper from "../../../../components/TabWrapper";
import { client } from "../../../ws/WSWrapper";

interface TankTabProps {}

const TankTab: React.FC<TankTabProps> = ({}) => {
  const {
    speed,
    turn_speed: turnSpeed,
    fire_rate: fireRate,
    explosion_radius: explosionRadius,
    setSpeed,
    setTurnSpeed,
    setFireRate,
    setExplosionRadius,
  } = useTankSpecsStore();

  const { gameInfo, players } = useGameInfoStore();

  const getAlreadyUsedColours = () => {
    const alreadyUsed = players
      .filter((p) => p.slot !== gameInfo?.yourSlot)
      .map((p) => p.colour);
    // console.log(alreadyUsed);
    return alreadyUsed;
  };
  let assignedPoints = speed + turnSpeed + fireRate + explosionRadius;
  let maxPoints = (gameInfo?.skillPoints || 8);
  return (
    <TabWrapper className="text-secondary dark:text-primary space-y-4">
      <h3 className="text-2xl font-bold text-gray-400 dark:text-primary">
        Colour
      </h3>
      <ColorPicker
        colours={gameInfo?.colours}
        selected={
          players.filter((p) => p.slot === gameInfo?.yourSlot)?.[0]?.colour
        }
        setSelected={(i) =>
          client.send(JSON.stringify({ type: "colour", colour: i }))
        }
        alreadySelectedColours={getAlreadyUsedColours()}
      />
      <div className="flex items-center justify-between">
        <h3 className="text-2xl font-bold text-gray-400 dark:text-primary">
          Spec
        </h3>
        { assignedPoints <= maxPoints
        ? <span className="text-sm text-gray-400 dark:text-primary"> Available Points: {maxPoints - assignedPoints} </span>
        : <span className="text-sm text-secondary-red-light"> Too many points! Reduce by {assignedPoints - maxPoints} </span>
        }
      </div>
      <div className="px-2 grid grid-cols-1 lg:grid-cols-2 items-center gap-6">
        <Slider
          label="Motor"
          description="Determines how fast your tank can move"
          value={speed}
          setValue={(v) => setSpeed(v)}
          max={4}
          className="w-full"
        />
        <Slider
          label="Oil"
          description="Determines how fast your tank can turn"
          value={turnSpeed}
          setValue={(v) => setTurnSpeed(v)}
          max={4}
          className="w-full"
        />
        <Slider
          label="Weapons"
          description="Determines how often your tank can fire"
          value={fireRate}
          setValue={(v) => setFireRate(v)}
          max={4}
          className="w-full"
        />
        <Slider
          label="Explosives"
          description="Determines your explosion radius"
          value={explosionRadius}
          setValue={(v) => setExplosionRadius(v)}
          max={4}
          className="w-full"
        />
      </div>
    </TabWrapper>
  );
};

export default TankTab;
