import React from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import {
  atomOneDark,
  atomOneLight,
} from "react-syntax-highlighter/dist/esm/styles/hljs";

import { useThemeStore } from "src/global-stores/useThemeStore";

interface CodeBlockProps {
  value: string;
  language?: string;
  style?: any;
}

const CodeBlock: React.FC<CodeBlockProps> = ({ language, value, style }) => {
  const theme = useThemeStore((s) => s.currentTheme);

  return (
    <SyntaxHighlighter
      language={language}
      wrapLongLines
      style={theme === "dark" ? atomOneDark : atomOneLight}
      customStyle={{ background: "transparent", fontSize: "15px" }}
    >
      {value}
    </SyntaxHighlighter>
  );
};

export default CodeBlock;
