import React, { useEffect, useRef, useState } from 'react';
import { useDimensions } from 'src/hooks/useDimentions';

interface AdaptiveGridProps {
    gap?: number
    childWidth: number
}

const AdaptiveGrid: React.FC<AdaptiveGridProps> = ({ children, gap = 0, childWidth }) => {
    const childs = React.Children.toArray(children);
    // const cW = childs?.[0]?.offsetWidth;
    const nChild = childs.length;

    const containerRef = useRef<HTMLDivElement>(null);
    // const childRef = useRef<HTMLDivElement>(null);
    const dims = useDimensions(containerRef);
    const [columns, setColumns] = useState(4);

    useEffect(() => {
      const containerWidth = dims.width;
      const finalContainerWidth = containerWidth - gap * (nChild || 0);
      setColumns(Math.round(finalContainerWidth / childWidth));
    }, [dims]);

    return(
        <div
            ref={containerRef}
            className={`grid grid-cols-${columns} gap-${gap / 4}`}
        >
        {/* {childs.map(c => {
            if(c) return React.cloneElement(c, { ref: childRef });
            return null;
        }) */}
        {children}
        {/* } */}
        </div>
    );
};

export default AdaptiveGrid;
