import { XCircleIcon } from "@heroicons/react/outline";
import React from "react";

interface SliderProps {
  value: number;
  setValue?: (v: number) => void;
  label?: string;
  description?: string;
  max: number;
  className?: string;
}

const Slider: React.FC<SliderProps> = ({
  value,
  setValue,
  label,
  description,
  max,
  className = "",
  ...props
}) => {
  let arr = [];
  for (let i = 0; i < max; i++) arr.push(i + 1);
  return (
    <div className="flex flex-col space-y-3">
      <div className="flex items-center justify-between">
        {label && (
          <span className="w-full text-lg">
            {label} ({value}/{max})
          </span>
        )}
        <div className="flex items-center space-x-4 rounded-lg shadow-xl p-2 bg-gray-100 dark:bg-gray-400">
          <button onClick={() => setValue?.(0)}>
            <XCircleIcon className="w-6 h-6" style={{ color: "#f20f40" }} />
          </button>
          {arr.map((v, i) => (
            <button
              key={i}
              onClick={() => setValue?.(v)}
              className={`w-6 h-6 rounded-full ${
                value - 1 >= i ? `bg-accent-script` : `bg-primary`
              }`}
            ></button>
          ))}
        </div>
      </div>
      {description && <span className="w-full text-xs">{description}</span>}
    </div>
  );
};

export default Slider;
