import React from "react";

interface LineHProps {
    x1: number;
    x2: number;
    y1: number;
    y2: number;
    className?: string;
}

const LineH: React.FC<LineHProps> = ({
    x1,
    x2,
    y1,
    y2,
    className,
}) => {
    return (
        <svg
            width={Math.abs(x1 - x2)}
            height={1}
            stroke="currentColor"
            className="text-gray-200 dark:text-gray-500"
            strokeWidth="2"
            viewBox={`0 0 ${Math.abs(x1 - x2)} ${1}`}
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            style={{
                position: "absolute",
                left: x1,
                bottom: y1,
            }}
        >
            <rect width={Math.abs(x1 - x2)} height={1} />
        </svg>
    );
};

export default LineH;
