import React from "react";
import LobbySelection from "src/modules/gamepage/gameside/LobbySelection";
import logo from 'src/img/logo.png';

interface CreateOrJoinGamePageProps {}

const CreateOrJoinGamePage: React.FC<CreateOrJoinGamePageProps> = ({}) => {
  return (
    <div className="h-full w-full flex items-center justify-center">
      <div className="flex flex-col items-center">
      <img src={logo} width="50%" height="50%" />
      <LobbySelection />
      </div>
    </div>
  );
};

export default CreateOrJoinGamePage;
