import { ArrowLeftIcon, XIcon } from "@heroicons/react/outline";
import React, { useState } from "react";
import Button from "./Button";

interface MultiStepFormProps {
  handlesSubmit: (values: any) => void;
  values: any;
  setSnapshot: React.Dispatch<React.SetStateAction<any>>;
  handleCloseModal?: () => void;
}

const MultiStepForm: React.FC<MultiStepFormProps> = ({
  children,
  handlesSubmit,
  values,
  setSnapshot,
  handleCloseModal,
}) => {
  const steps = React.Children.toArray(children);
  const [stepNumber, setStepNumber] = useState(0);
  const step = steps[stepNumber];
  const totalSteps = steps.length;
  const isLastStep = stepNumber === totalSteps - 1;

  const next = (v: any) => {
    setSnapshot(v);
    setStepNumber(Math.min(stepNumber + 1, totalSteps - 1));
  };

  const previous = (v: any) => {
    setSnapshot(v);
    setStepNumber(Math.max(stepNumber - 1, 0));
  };

  return (
    <div className="w-full h-full relative">
      <div className="flex flex-col items-center justify-between h-full">
        <div className="w-full flex items-center justify-betweenbg-primary dark:bg-gray-500 p-4 rounded-lg">
          <div className="w-full flex items-center justify-between">
            {stepNumber > 0 && (
              <Button
                transparent
                padding={false}
                icon={
                  <ArrowLeftIcon
                    className="w-6 h-6"
                    onClick={() => previous(values)}
                  />
                }
              />
            )}
            {handleCloseModal && (
              <Button
                transparent
                icon={<XIcon className="w-6 h-6" />}
                onClick={handleCloseModal}
                padding={false}
              />
            )}
          </div>
        </div>
        <div className="relative h-full w-full">
          <div className="p-4 absolute top-0 overflow-y-auto w-full h-full">
            {step}
          </div>
        </div>
        <div className="w-full flex items-center relative justify-end p-4 bg-primary dark:bg-gray-500 rounded-lg">
          <div className="flex space-x-2 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            {steps.map((_, i) => (
              <div
                key={i}
                className={`w-3 h-3 rounded-full ${
                  stepNumber === i
                    ? `bg-gray-200 dark:bg-primary`
                    : `bg-gray-100 dark:bg-gray-200`
                }`}
              ></div>
            ))}
          </div>
          <Button
            rounded
            label={isLastStep ? `Create Game` : `Next`}
            onClick={isLastStep ? handlesSubmit : () => next(values)}
            textColorLight="primary"
          />
        </div>
      </div>
    </div>
  );
};

export default MultiStepForm;
