import { BanIcon } from "@heroicons/react/outline";
import * as React from "react";

interface TabProps {
  label: string;
  icon?: React.ReactNode;
  width?: string;
  className?: string;
  selected?: boolean;
  setSelected?: (i: number) => void;
  handleClickWhenDisabled?: () => void;
  index?: number;
  disabled?: boolean;
}

const Tab: React.FC<TabProps> = ({
  label,
  icon,
  className,
  selected = false,
  setSelected = () => {},
  index = 0,
  width,
  disabled = false,
  handleClickWhenDisabled,
}) => {
  const cs = `${className} ${width} ${
    !selected
      ? `text-gray-200 bg-primary dark:bg-gray-600 h-full flex items-center justify-center relative`
      : `relative bg-accent-script text-primary h-full flex items-center justify-center rounded-t-lg transition duration-500 ease-linear`
  }`;

  return (
    <button
      className={cs}
      onClick={() => {
        if (!disabled) setSelected(index);
        else handleClickWhenDisabled?.();
      }}
    >
      {disabled && (
        <BanIcon className="w-6 h-6 text-secondary-red-light absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
      )}
      {!disabled && label}
    </button>
  );
};

export default Tab;
